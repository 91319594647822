import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M3614 9852 c-6 -4 -16 -35 -22 -69 -7 -35 -17 -90 -23 -123 -7 -33
-9 -86 -6 -118 3 -38 0 -70 -9 -91 -17 -41 -19 -272 -3 -303 6 -11 12 -59 12
-107 l2 -87 -27 1 c-15 1 -33 6 -40 12 -7 5 -40 15 -72 22 -33 6 -63 15 -66
21 -3 5 -21 6 -43 1 -28 -7 -37 -6 -37 4 0 19 -20 24 -52 12 -33 -11 -36 -23
-7 -30 13 -4 18 -10 14 -18 -4 -6 -7 -49 -6 -95 2 -54 -2 -84 -8 -84 -7 0 -12
-44 -13 -122 -1 -68 -3 -104 -5 -80 -3 28 -9 42 -19 42 -11 0 -14 -22 -14
-120 0 -73 -4 -120 -10 -120 -6 0 -10 -73 -10 -199 0 -110 -4 -201 -9 -203
-14 -5 -26 -158 -12 -158 6 0 11 -7 11 -15 0 -10 -10 -15 -26 -15 -23 0 -26
-3 -22 -25 4 -17 1 -25 -8 -25 -8 0 -14 -6 -14 -14 0 -20 -33 -26 -55 -10 -10
8 -27 14 -37 14 -10 0 -18 4 -18 9 0 4 -21 14 -47 21 -27 7 -66 21 -88 31
-156 68 -325 129 -358 129 -33 0 -38 -3 -32 -19 4 -13 0 -23 -12 -30 -17 -10
-17 -10 0 -11 14 0 17 -10 17 -59 0 -33 4 -61 9 -63 5 -1 10 -30 12 -62 l4
-60 -35 -8 c-19 -5 -45 -13 -57 -18 -57 -25 -71 -30 -113 -44 -43 -14 -134
-48 -175 -65 -145 -62 -253 -117 -370 -188 -11 -7 -30 -18 -42 -24 -13 -7 -23
-16 -23 -20 0 -27 298 -344 394 -420 16 -12 43 -35 60 -51 17 -16 43 -37 58
-46 16 -9 28 -20 28 -24 0 -4 5 -8 10 -8 6 0 21 -9 33 -21 22 -21 99 -73 217
-146 85 -52 214 -120 313 -162 42 -19 77 -37 77 -40 0 -3 -25 -19 -56 -35 -31
-16 -58 -35 -61 -43 -3 -7 -11 -13 -17 -13 -7 0 -21 -8 -32 -18 -10 -10 -28
-23 -38 -29 -40 -22 -270 -242 -279 -267 -4 -9 -12 -16 -19 -16 -7 0 -18 -11
-24 -25 -10 -21 -9 -25 9 -31 19 -6 19 -7 3 -16 -9 -5 -24 -7 -31 -4 -8 3 -17
1 -20 -4 -4 -6 13 -10 39 -10 26 0 46 -5 49 -12 3 -8 9 -1 16 15 12 30 24 36
20 10 -3 -20 70 -99 88 -95 6 1 27 -13 46 -33 27 -27 42 -35 73 -36 21 -1 31
-3 22 -6 -22 -6 -23 -17 -3 -33 8 -7 15 -21 15 -31 0 -13 7 -19 21 -19 12 0
30 -11 41 -25 15 -19 29 -25 58 -25 40 0 43 -3 81 -52 18 -24 28 -28 70 -28
42 0 50 -3 48 -17 -6 -62 -2 -66 51 -61 53 5 75 -7 65 -35 -4 -9 4 -23 19 -35
17 -13 26 -30 26 -47 0 -27 1 -27 50 -23 42 4 53 2 71 -18 11 -12 18 -28 14
-34 -4 -6 -1 -18 6 -26 10 -12 14 -12 21 -2 6 10 16 5 38 -20 34 -36 39 -69
13 -79 -22 -8 -53 -39 -53 -53 0 -5 -5 -10 -11 -10 -12 0 -45 -24 -112 -80
-23 -19 -58 -46 -77 -60 -19 -13 -48 -36 -65 -51 -16 -15 -48 -43 -70 -63 -24
-21 -34 -36 -25 -36 8 0 20 -10 25 -22 8 -19 18 -23 53 -22 23 1 42 -2 42 -7
0 -13 73 -33 112 -30 26 2 44 -4 60 -18 21 -21 38 -25 89 -21 18 1 31 -7 43
-25 17 -26 19 -27 100 -20 80 7 85 6 127 -21 42 -27 48 -28 157 -26 l112 3 0
-26 c0 -23 3 -25 50 -25 28 0 50 -4 50 -9 0 -16 40 -24 55 -11 11 9 12 7 8 -9
-4 -17 -1 -21 22 -21 18 0 28 6 32 20 3 11 12 20 20 20 11 0 13 -7 9 -24 -5
-19 -1 -25 16 -29 12 -4 24 -4 27 -2 2 3 -1 5 -7 5 -7 0 -12 10 -12 21 0 12
-12 37 -27 55 -61 75 -81 102 -123 169 -25 39 -48 72 -52 75 -4 3 -12 17 -17
30 -11 25 -22 45 -51 90 -30 46 -145 311 -171 395 -7 22 -19 47 -26 55 -6 9
-15 36 -18 60 -4 25 -10 54 -14 65 -20 50 -30 95 -37 155 -4 36 -10 70 -14 75
-15 18 -40 308 -39 445 1 167 25 449 40 465 5 5 9 22 9 39 0 16 4 43 9 60 5
17 15 51 22 76 41 156 58 209 90 295 42 109 174 378 214 435 18 26 50 78 72
116 22 38 47 79 56 89 125 152 191 226 273 305 96 93 258 228 319 265 17 10
53 33 80 51 95 60 140 85 290 158 180 88 280 129 360 151 33 9 81 23 107 30
116 34 339 68 549 83 98 7 119 11 139 30 36 34 36 17 0 -24 -24 -28 -31 -43
-27 -63 13 -59 20 -86 27 -91 16 -13 225 -298 300 -410 46 -69 130 -187 188
-264 83 -111 99 -138 81 -133 -111 27 -119 28 -173 18 -86 -16 -201 -68 -311
-142 -55 -36 -119 -76 -143 -88 -27 -14 -42 -28 -40 -38 2 -9 -3 -18 -10 -20
-8 -3 -10 1 -5 14 13 34 -60 -30 -94 -83 -38 -61 -81 -104 -100 -104 -13 0
-13 2 -2 17 12 14 12 15 -1 10 -8 -4 -23 -10 -32 -13 -10 -4 -18 -12 -18 -19
0 -15 -51 -55 -70 -55 -18 0 -1 20 28 32 l27 12 -24 4 c-14 2 -35 -5 -50 -17
-14 -11 -63 -45 -108 -76 -46 -31 -83 -64 -83 -72 0 -14 3 -13 20 2 11 10 20
13 20 8 0 -6 -7 -16 -15 -23 -8 -7 -13 -15 -11 -19 3 -4 -18 -35 -45 -70 -27
-35 -49 -68 -49 -75 0 -17 -80 -106 -95 -106 -8 0 -27 -21 -42 -47 -15 -27
-33 -54 -40 -61 -16 -17 -17 -23 0 -16 6 3 28 25 47 49 26 33 37 41 47 33 14
-11 7 -32 -7 -23 -9 6 -31 -30 -58 -90 -12 -28 -11 -28 23 10 28 31 35 35 30
18 -11 -39 12 -25 29 17 9 22 18 40 21 40 2 0 2 -7 -1 -15 -4 -8 -1 -15 5 -15
6 0 11 6 11 14 0 8 14 24 30 36 43 31 37 8 -11 -42 -22 -24 -47 -55 -55 -70
-8 -16 -20 -28 -27 -28 -12 0 -37 -65 -37 -97 0 -9 -10 -27 -22 -40 -17 -18
-18 -23 -6 -23 11 0 15 -7 12 -22 -4 -21 -4 -22 5 -3 5 11 15 35 21 53 7 17
17 32 22 32 9 0 6 -31 -5 -62 -16 -42 -19 -57 -22 -123 l-4 -70 15 45 c8 25
16 62 19 82 3 34 52 115 62 104 5 -5 -16 -44 -34 -64 -13 -13 -17 -32 -9 -32
3 0 12 7 20 16 14 14 15 12 9 -18 -8 -42 -28 -83 -63 -128 -33 -41 -46 -88
-44 -150 8 -219 33 -379 34 -220 0 41 4 102 10 134 10 62 6 81 -11 56 -8 -12
-9 -9 -7 10 2 14 9 31 16 38 6 7 12 22 12 33 0 28 19 61 31 54 5 -4 9 2 9 12
0 10 9 37 20 58 12 23 18 50 15 62 -3 13 0 32 7 43 9 14 10 22 1 31 -8 8 -3
25 18 66 16 30 29 66 29 79 0 13 4 24 9 24 5 0 11 10 14 22 4 12 9 19 12 16 4
-3 -1 -20 -9 -37 -15 -28 -15 -30 0 -24 12 4 9 -7 -12 -48 -20 -40 -28 -73
-31 -124 -3 -65 -14 -98 -64 -197 -7 -13 -5 -16 6 -12 8 4 15 17 15 30 0 13 5
24 10 24 14 0 12 -54 -6 -135 -9 -38 -18 -88 -21 -110 -5 -38 -4 -39 11 -20 8
11 16 34 16 50 0 17 4 36 9 43 8 13 33 -99 33 -148 0 -31 15 -60 31 -59 7 0 7
2 2 5 -13 5 -6 97 9 106 7 5 15 -10 22 -45 6 -33 15 -51 23 -49 10 2 12 -10 9
-47 -2 -29 1 -49 7 -48 6 1 9 -8 8 -20 -2 -15 3 -23 12 -23 9 0 15 -9 15 -24
0 -41 -19 -39 -37 4 -10 22 -21 40 -25 40 -4 0 -6 19 -4 43 2 23 2 33 -1 22
-6 -27 -23 -44 -23 -23 0 8 -13 26 -30 40 -16 14 -36 42 -43 63 -12 35 -13 30
-10 -74 3 -68 0 -111 -5 -107 -12 7 16 -75 29 -89 7 -7 8 6 4 35 -5 37 -4 42
5 26 7 -10 10 -33 7 -51 -3 -19 1 -54 8 -79 8 -26 12 -52 9 -59 -2 -7 3 -21
12 -32 8 -11 13 -15 9 -8 -3 7 -7 29 -9 49 -2 32 -1 34 11 18 7 -10 13 -23 13
-29 0 -17 24 -65 33 -65 10 0 9 18 -3 25 -5 3 -10 13 -10 22 0 9 43 -27 98
-81 72 -73 98 -93 101 -80 2 10 -8 26 -23 37 -14 10 -26 26 -26 35 0 14 -2 14
-9 3 -7 -11 -18 -5 -50 29 -23 24 -41 53 -41 65 0 12 -13 40 -30 63 -16 22
-32 52 -36 66 -3 13 -12 27 -19 30 -7 2 -16 20 -20 38 -4 18 -14 37 -22 43
-10 7 -13 25 -11 55 l4 45 13 -32 c7 -18 17 -31 21 -28 9 5 17 -31 18 -74 1
-13 6 -29 13 -35 9 -8 10 -4 5 19 -5 22 -4 26 4 15 5 -8 10 -20 10 -27 0 -7
13 -33 30 -59 16 -26 32 -65 35 -88 4 -23 15 -53 26 -66 l19 -25 0 23 c0 12 7
25 15 28 10 4 12 15 8 35 -7 37 2 37 21 1 8 -15 23 -28 35 -29 25 -2 26 1 6
31 -10 15 -15 44 -13 89 1 55 -2 70 -17 81 -16 12 -17 21 -10 87 4 41 7 88 7
104 -2 34 -21 77 -33 70 -4 -3 -13 19 -19 48 -7 28 -19 59 -27 68 -7 9 -13 20
-12 25 4 23 -2 54 -10 54 -5 0 -7 -10 -5 -22 4 -16 -1 -12 -15 12 -28 49 -44
130 -32 161 9 22 10 20 11 -16 1 -22 7 -56 14 -75 12 -34 13 -34 24 -13 7 12
12 36 12 53 0 17 4 29 8 26 5 -3 8 16 7 42 -2 26 0 53 3 60 6 18 32 -54 32
-91 0 -43 14 -66 87 -143 49 -51 59 -56 38 -14 -22 43 4 30 41 -20 16 -22 34
-38 41 -35 6 2 17 -3 24 -11 6 -8 14 -12 16 -10 7 6 -58 96 -69 96 -15 0 -108
154 -108 178 0 13 -4 20 -10 17 -6 -4 -8 -21 -4 -41 8 -43 -1 -44 -22 -1 -9
17 -21 34 -25 37 -13 9 -29 139 -21 174 l6 31 13 -40 13 -40 -7 40 c-10 58 -6
212 6 232 6 10 11 12 11 6 0 -21 16 -15 27 10 10 21 10 21 17 -11 11 -49 23
-30 31 48 4 39 11 73 16 76 5 3 9 18 9 35 0 16 5 29 10 29 6 0 10 -11 10 -24
0 -14 -4 -28 -10 -31 -12 -8 -13 -35 -1 -35 5 0 13 21 18 48 l9 47 16 -51 c18
-59 37 -95 56 -107 6 -4 12 -15 12 -24 0 -13 3 -14 12 -5 8 8 7 22 -5 54 -10
24 -17 53 -18 63 -1 16 -3 15 -8 -5 -7 -23 -9 -20 -23 26 -9 28 -24 66 -32 83
-18 34 -13 95 8 109 7 4 25 25 42 46 16 22 23 37 17 32 -8 -4 -13 -2 -13 8 0
15 48 66 61 66 4 0 10 7 13 16 5 14 4 15 -9 4 -25 -21 -29 -3 -6 26 11 14 18
31 14 37 -3 6 -1 7 5 3 13 -8 52 10 52 24 0 5 -7 10 -16 12 -13 2 -11 9 12 32
62 61 100 86 114 73 7 -7 10 -7 7 -1 -5 9 50 44 68 44 14 0 1 -17 -24 -31 -20
-12 -28 -24 -28 -43 0 -20 4 -26 14 -22 8 3 17 6 19 6 2 0 4 6 4 13 1 6 16 22
35 34 48 30 44 17 -10 -35 -53 -51 -59 -72 -12 -45 17 11 43 26 57 34 55 31
135 109 112 109 -5 0 -26 -11 -46 -25 -20 -13 -36 -22 -36 -20 0 3 14 24 30
47 35 48 32 62 -7 27 -20 -18 -25 -20 -20 -6 4 10 2 17 -5 17 -7 0 1 13 16 29
18 19 34 27 45 24 9 -4 13 -3 9 2 -19 20 174 85 254 85 91 0 294 -91 448 -200
56 -40 136 -95 178 -123 42 -27 109 -76 150 -109 40 -32 77 -58 81 -58 5 0 19
-10 33 -22 84 -75 138 -116 138 -104 0 4 9 2 20 -4 11 -6 20 -20 20 -31 0 -11
5 -17 10 -14 6 4 79 -61 163 -143 83 -82 184 -176 222 -209 39 -33 84 -77 100
-97 17 -20 39 -48 51 -60 36 -41 37 -122 2 -210 -10 -26 -12 -27 -19 -10 -6
16 -8 14 -8 -9 -1 -17 -5 -26 -11 -22 -6 4 -9 3 -8 -2 2 -4 -5 -29 -15 -55
-22 -56 -92 -123 -146 -138 -42 -12 -108 -13 -113 -2 -1 4 -24 24 -50 45 -27
21 -48 41 -48 45 0 11 59 42 79 42 10 0 30 19 45 43 l28 42 -34 -32 c-19 -18
-42 -33 -52 -33 -16 0 -15 5 9 46 29 49 29 66 0 34 -10 -11 -24 -20 -32 -20
-20 0 -16 34 7 55 11 10 20 25 20 33 0 8 -11 2 -29 -17 -16 -17 -35 -31 -42
-31 -7 0 -33 -14 -59 -30 -44 -28 -80 -32 -80 -9 0 5 -8 9 -17 9 -15 0 -16 2
-3 10 19 13 43 13 35 0 -8 -13 85 29 115 52 31 25 49 67 39 93 -9 23 -10 21
-15 -18 -5 -47 -29 -74 -71 -81 -21 -3 -48 -9 -60 -12 -14 -4 -23 -2 -23 5 0
6 6 11 13 11 7 0 36 15 66 34 64 42 69 46 42 39 -12 -3 -24 -9 -27 -14 -3 -5
-10 -9 -16 -9 -6 0 -1 12 13 27 16 19 18 23 4 12 -22 -17 -102 -60 -150 -79
-16 -7 -37 -16 -45 -20 -17 -8 -70 15 -70 30 0 6 -10 10 -22 10 -71 2 -236
146 -276 241 -26 60 -36 102 -22 94 5 -3 10 -12 10 -19 0 -7 11 -26 24 -41 14
-16 33 -44 43 -62 10 -18 21 -33 25 -33 5 0 8 -6 8 -12 0 -7 8 -22 17 -33 l15
-20 -5 20 c-3 11 -15 44 -26 73 -12 28 -17 52 -13 52 10 0 33 -45 39 -72 5
-28 45 -109 51 -104 2 3 -3 23 -12 46 -9 22 -16 46 -16 53 1 13 50 -43 50 -56
0 -5 7 -24 16 -43 17 -36 40 -47 29 -15 -8 26 59 -36 69 -63 5 -15 12 -17 44
-11 20 4 42 11 48 16 6 5 19 7 28 3 21 -8 21 15 0 31 -9 6 -17 25 -18 42 -1
25 -5 30 -28 30 -15 0 -44 11 -65 25 -30 20 -42 37 -56 80 -21 67 -21 92 -2
112 8 8 12 22 9 30 -3 9 -1 16 5 16 6 0 11 10 11 22 0 21 0 21 -15 2 -8 -10
-15 -28 -15 -40 0 -11 -4 -24 -9 -30 -16 -18 -19 -69 -6 -109 10 -27 10 -35 2
-24 -7 9 -17 14 -23 12 -6 -2 -16 8 -24 22 -7 14 -17 25 -21 25 -10 0 -30 61
-40 124 -7 49 -8 48 -6 -21 3 -70 2 -72 -16 -55 -10 9 -25 30 -33 47 -24 50
-86 142 -78 115 13 -46 23 -68 33 -75 15 -9 14 -36 -1 -32 -17 3 -78 86 -78
106 -1 9 -8 27 -16 41 -10 17 -13 18 -9 5 4 -11 10 -29 13 -40 5 -19 5 -19
-12 -2 -31 31 -37 18 -11 -23 14 -22 30 -62 35 -88 13 -61 26 -113 35 -135 6
-13 3 -16 -9 -11 -9 3 -16 12 -16 19 0 7 -13 44 -29 81 -16 38 -35 96 -42 129
-15 72 -33 100 -23 36 10 -64 -8 -46 -46 45 -30 71 -70 147 -70 132 0 -4 9
-31 19 -60 10 -29 22 -71 25 -93 3 -22 8 -56 11 -75 l5 -35 -16 28 c-21 38
-30 27 -9 -12 8 -17 11 -31 6 -31 -11 0 -33 59 -26 71 3 4 -3 25 -13 46 -11
21 -23 53 -27 71 -3 18 -10 30 -14 27 -5 -2 -13 7 -20 22 -6 14 -14 23 -17 20
-3 -3 -1 -13 5 -24 31 -56 63 -153 51 -153 -11 0 -90 162 -90 185 0 9 -9 28
-20 42 -22 29 -26 19 -9 -24 16 -38 49 -177 44 -182 -12 -11 -34 25 -40 64 -4
28 -11 45 -20 45 -18 0 -27 16 -36 60 -6 33 -7 32 -8 -15 -2 -47 -3 -49 -17
-30 -8 11 -17 37 -21 59 -5 35 -7 37 -19 21 -12 -16 -14 -17 -20 -2 -3 10 -10
17 -15 17 -15 0 1 -102 22 -138 11 -18 19 -39 19 -46 0 -26 100 -96 253 -176
26 -14 60 -35 75 -48 20 -17 10 -14 -42 13 -38 19 -71 33 -73 31 -4 -3 18 -18
102 -71 43 -27 93 -66 133 -103 18 -17 42 -34 53 -37 10 -4 19 -10 19 -15 0
-7 92 -74 224 -162 37 -26 52 -44 67 -83 25 -65 25 -88 -1 -57 -11 13 -21 18
-25 12 -3 -5 8 -25 24 -42 17 -18 31 -37 31 -42 0 -5 7 -14 16 -20 9 -7 17
-30 18 -57 2 -25 7 -56 12 -70 7 -19 10 -11 14 41 3 36 3 81 1 100 l-5 35 -8
-30 c-5 -23 -6 -18 -4 20 2 28 2 42 -1 33 -2 -10 -8 -18 -11 -18 -7 0 -42 78
-42 94 0 16 40 -26 41 -43 0 -10 2 -12 6 -3 7 17 23 15 23 -3 0 -8 5 -15 11
-15 7 0 8 -12 5 -32 -6 -31 -5 -31 5 -8 16 35 24 7 9 -30 -13 -32 -6 -135 10
-145 5 -3 12 25 15 62 9 87 16 100 39 78 20 -21 16 -41 -8 -38 -18 1 -18 -10
0 -69 3 -10 1 -18 -4 -18 -5 0 -12 -11 -14 -25 -3 -14 -2 -25 1 -25 9 0 31 51
32 74 1 12 5 6 9 -14 5 -19 7 -46 4 -60 -5 -24 -5 -24 10 -6 9 11 17 25 18 30
0 6 4 -9 7 -34 l6 -45 27 55 c15 30 28 48 28 40 0 -11 4 -10 16 5 16 19 16 19
12 -7 -4 -18 -1 -25 6 -20 67 41 83 -108 22 -196 -16 -23 -26 -48 -23 -56 7
-17 -25 -47 -80 -76 -63 -32 -109 -35 -172 -11 -30 11 -58 18 -62 15 -17 -10
5 32 25 48 12 10 16 18 10 18 -6 0 -19 -9 -29 -20 -30 -33 -83 -19 -55 15 10
12 7 12 -19 2 -37 -14 -90 3 -186 59 -148 86 -179 118 -224 232 -10 25 -11 24
-5 -11 3 -21 7 -46 10 -55 3 -9 -2 -4 -11 12 -13 22 -19 26 -26 15 -9 -15 -7
-21 -33 86 -9 38 -21 74 -26 79 -14 14 -13 -20 4 -84 8 -30 13 -56 11 -58 -6
-7 -45 19 -45 29 0 6 -4 8 -9 4 -5 -3 -16 2 -24 10 -9 8 -20 15 -26 15 -5 0
-18 11 -29 25 -11 14 -27 25 -35 25 -9 0 -24 10 -34 23 -10 13 -23 22 -29 20
-20 -7 -41 33 -59 110 -11 48 -21 73 -26 65 -4 -7 -1 -38 7 -67 14 -56 12 -73
-7 -54 -6 6 -13 44 -14 85 -2 40 -10 89 -19 108 -16 34 -17 34 -11 5 11 -62
24 -180 19 -184 -2 -2 -13 4 -24 14 -11 10 -30 23 -43 29 -17 9 -23 24 -29 70
-4 33 -11 62 -14 65 -4 3 -4 -21 0 -54 10 -86 -14 -57 -31 37 -12 68 -12 69
-8 16 3 -37 1 -58 -5 -58 -14 0 -30 44 -30 83 0 18 -5 39 -10 47 -7 11 -10 2
-10 -32 0 -27 -5 -48 -11 -48 -5 0 -7 -4 -4 -10 3 -5 2 -10 -2 -10 -5 0 -19
28 -31 63 l-21 62 5 -60 c5 -56 5 -58 -9 -34 -8 15 -19 39 -25 55 -9 25 -11
26 -11 7 -1 -35 18 -76 51 -108 17 -16 41 -46 53 -65 16 -25 49 -50 114 -85
50 -27 91 -54 91 -60 0 -6 30 -23 66 -39 36 -16 74 -37 83 -46 9 -10 44 -32
78 -50 34 -19 64 -39 67 -47 4 -9 -2 -9 -20 3 -13 9 -27 15 -29 12 -3 -3 39
-29 92 -58 54 -29 110 -61 126 -72 15 -10 72 -41 127 -67 55 -27 111 -58 125
-69 14 -11 48 -23 75 -27 28 -4 66 -18 85 -31 20 -13 40 -24 45 -24 5 0 7 -6
4 -13 -2 -7 0 -24 5 -38 9 -22 10 -21 10 15 1 32 4 37 19 31 9 -5 46 -10 82
-12 61 -5 70 -2 150 39 82 41 86 45 108 98 23 55 25 130 5 200 -6 22 -5 24 10
11 9 -7 17 -17 17 -22 0 -11 60 -109 77 -127 7 -7 13 -18 13 -23 0 -5 11 -9
23 -9 13 0 32 -9 41 -19 10 -11 28 -22 42 -25 13 -4 24 -13 24 -21 0 -8 5 -15
10 -15 18 0 101 -94 96 -107 -3 -7 0 -15 6 -19 7 -5 8 -2 3 6 -5 9 -4 11 4 6
6 -4 9 -11 6 -15 -2 -5 37 -51 87 -102 79 -82 92 -91 95 -72 5 25 -55 157 -85
189 -11 11 -23 30 -26 42 -4 12 -12 21 -18 19 -6 -1 -21 12 -32 30 -12 18 -27
30 -33 28 -7 -2 -16 7 -19 22 -5 20 -2 31 16 45 18 14 19 18 5 18 -9 0 -24 -4
-34 -9 -15 -9 -17 -6 -13 20 4 26 2 28 -16 22 -11 -5 -36 -23 -56 -40 -49 -46
-53 -26 -4 22 60 60 72 95 32 95 -10 0 -13 7 -9 23 4 15 1 28 -9 36 -9 8 -16
20 -16 28 0 11 -7 14 -21 10 -16 -4 -19 -2 -15 10 7 18 -32 86 -45 77 -24 -14
-4 14 50 72 34 35 74 93 93 131 29 61 33 77 33 154 0 93 2 88 -91 209 -19 25
-37 53 -40 63 -4 9 -12 17 -18 17 -22 0 -86 75 -86 100 0 33 15 54 30 45 8 -5
7 0 -2 14 -7 12 -18 21 -25 21 -7 0 -17 9 -23 19 -9 18 -7 19 22 16 34 -5 107
-51 109 -69 1 -6 1 -27 1 -46 -1 -30 1 -29 9 13 9 45 8 51 -18 82 -35 42 -87
69 -119 61 -27 -7 -29 -1 -10 33 8 14 12 30 9 37 -7 18 -95 -82 -91 -103 3
-14 10 -8 31 25 15 23 29 42 31 42 7 0 -13 -63 -22 -69 -21 -15 -22 -45 -2
-63 l21 -20 -5 39 -6 38 11 -34 c9 -30 11 -32 23 -16 18 25 29 4 20 -39 l-6
-35 -51 42 c-101 84 -144 134 -152 179 -6 32 -9 37 -12 20 -6 -32 -32 -25 -56
16 -28 48 -11 101 56 170 41 41 47 52 31 52 -11 0 -29 -7 -41 -15 -12 -9 -26
-15 -30 -14 -4 1 -16 -1 -26 -6 -9 -4 -23 -3 -30 3 -9 8 -16 6 -25 -9 -19 -30
-32 -23 -32 18 0 48 -7 39 -14 -17 -2 -25 -5 -46 -5 -47 -1 -2 -5 -3 -10 -3
-6 0 -14 19 -19 43 l-9 42 -8 -42 c-11 -64 -25 -52 -26 25 -1 37 -4 56 -6 42
-3 -14 -9 -30 -14 -36 -4 -5 -5 -21 -2 -35 6 -21 4 -22 -9 -11 -9 7 -18 26
-22 43 -3 17 -13 37 -21 45 -8 9 -15 32 -15 52 -1 30 -3 34 -11 19 -5 -9 -11
-35 -12 -57 -1 -44 -11 -51 -26 -21 -6 10 -23 23 -38 29 -31 11 -180 114 -203
141 -8 9 -29 25 -47 36 -20 11 -29 24 -26 33 5 10 1 13 -14 8 -10 -3 -28 0
-39 9 -10 8 -14 15 -6 15 20 0 14 28 -8 35 -16 5 -18 12 -13 37 5 24 3 30 -8
26 -17 -6 -39 15 -39 35 0 8 -7 18 -16 21 -8 3 -14 14 -13 23 4 33 -11 53 -36
47 -20 -5 -22 -2 -18 20 3 14 2 33 -1 42 -6 16 -8 16 -24 0 -29 -25 -38 -19
-12 9 22 23 22 26 7 43 -10 10 -17 24 -17 30 0 7 -12 12 -27 12 -20 0 -24 3
-16 11 14 14 -4 59 -24 59 -7 0 -13 7 -13 15 0 8 -5 15 -11 15 -6 0 -9 7 -5
15 3 8 0 15 -7 15 -13 0 -37 45 -37 70 0 15 -35 43 -55 45 -5 1 -9 6 -7 13 1
7 -6 12 -15 13 -11 0 -13 3 -5 6 6 2 12 12 12 21 0 12 -3 13 -12 4 -17 -17
-33 -15 -18 3 18 22 6 29 -22 14 -23 -12 -23 -12 -4 10 17 19 15 26 -6 22 -5
0 -8 3 -8 9 0 5 5 10 10 10 6 0 10 5 10 11 0 6 -7 8 -16 5 -11 -4 -14 -1 -10
12 3 9 -3 27 -14 40 -17 20 -22 22 -42 11 -22 -12 -23 -11 -5 8 22 25 22 36 0
29 -27 -8 -51 10 -35 26 17 17 15 31 -4 24 -12 -5 -15 -2 -10 10 8 21 -31 65
-51 57 -11 -4 -13 0 -7 21 7 28 -4 33 -37 16 -23 -13 -25 -3 -2 22 9 10 11 18
5 18 -6 0 -14 -4 -17 -10 -8 -12 -25 -13 -25 -1 0 14 78 51 95 45 19 -8 172
-211 261 -347 280 -428 544 -797 570 -797 8 0 14 5 14 10 0 6 -4 10 -10 10 -5
0 -10 6 -10 13 0 7 -13 26 -30 42 -18 17 -30 39 -30 54 0 14 -7 46 -15 71 -9
25 -14 33 -12 18 2 -15 0 -25 -4 -22 -5 3 -9 13 -9 22 0 9 -5 24 -11 32 -8 11
-9 6 -7 -23 l4 -37 -23 27 c-12 16 -20 35 -17 44 6 21 -4 46 -21 52 -9 4 -11
-1 -8 -16 5 -18 3 -20 -7 -10 -7 7 -14 29 -16 50 -2 21 -4 39 -5 41 -1 1 -2 5
-3 10 -1 4 -6 22 -10 40 -8 33 -20 42 -29 20 -3 -7 -6 -3 -6 10 -1 12 -5 22
-9 22 -5 0 -7 -15 -5 -32 l4 -33 -17 30 c-9 17 -25 35 -34 42 -9 6 -22 36 -29
66 -9 40 -17 56 -32 60 -10 3 -26 21 -34 40 -8 20 -19 38 -25 42 -5 3 -10 15
-10 26 0 11 -4 18 -9 15 -5 -4 -13 13 -17 36 -4 24 -9 35 -12 26 -4 -20 -20
-24 -19 -5 1 29 -3 50 -9 44 -14 -14 -72 44 -78 79 -4 19 -11 32 -17 29 -5 -4
-9 4 -9 17 0 18 -2 20 -10 8 -5 -8 -11 -12 -13 -10 -2 3 -31 41 -65 85 -34 44
-70 90 -79 103 -14 18 -25 22 -48 19 -41 -7 -135 -51 -163 -77 -26 -24 -42
-21 -17 3 8 9 15 19 15 23 0 5 17 26 38 48 25 26 31 37 17 31 -44 -16 -50 -15
-62 10 -11 25 -10 27 33 46 42 17 48 18 81 4 20 -9 43 -24 50 -35 17 -24 29
-25 65 -6 24 13 30 13 43 0 21 -22 19 -34 -7 -34 -21 0 -21 0 -2 -15 22 -16
48 -20 39 -5 -8 13 1 13 26 0 21 -12 25 -36 7 -42 -7 -3 13 -37 48 -84 33 -43
101 -142 151 -220 81 -127 153 -226 296 -408 26 -34 71 -93 100 -133 28 -40
56 -72 60 -72 15 -1 -37 81 -140 223 -153 210 -335 464 -373 521 -18 28 -53
73 -77 102 -23 28 -43 53 -43 56 0 3 17 1 38 -4 20 -5 55 -13 77 -18 120 -26
251 -67 385 -120 161 -64 201 -74 208 -52 3 9 0 11 -9 7 -8 -4 -4 1 9 10 12 9
22 24 22 34 0 9 11 26 25 36 14 10 25 21 25 24 0 3 33 40 73 81 97 102 137
147 137 156 1 5 14 19 30 33 17 13 30 30 30 36 0 7 7 20 16 29 13 12 14 19 4
30 -17 20 -38 19 -43 -2 -3 -11 -5 -7 -6 10 l-1 27 -84 0 c-46 0 -86 -4 -88
-9 -1 -5 -13 -1 -26 9 -22 18 -23 23 -26 262 -4 226 -14 349 -47 533 -14 80
-26 89 -92 63 -282 -109 -504 -223 -659 -338 -33 -25 -74 -53 -91 -63 -18 -10
-36 -24 -42 -30 -5 -7 -32 -30 -60 -52 -27 -22 -80 -69 -116 -105 -147 -144
-149 -146 -159 -130 -5 8 -10 30 -10 48 0 17 -4 32 -10 32 -5 0 -10 14 -10 30
0 17 -4 38 -9 48 -16 29 -71 176 -71 188 0 7 -29 68 -63 135 -63 123 -97 160
-97 105 0 -20 -5 -26 -21 -26 -14 0 -19 -5 -16 -14 8 -20 -31 -55 -42 -38 -6
10 -11 9 -21 -3 -12 -14 -11 -18 1 -26 10 -6 3 -9 -23 -9 -31 0 -38 -4 -38
-20 0 -17 -7 -20 -39 -20 -25 0 -41 -5 -45 -15 -3 -8 -11 -15 -18 -15 -7 0
-19 -9 -26 -20 -7 -11 -19 -20 -27 -20 -8 0 -26 -18 -40 -41 -20 -30 -31 -39
-44 -34 -13 4 -25 -3 -39 -20 -11 -14 -27 -25 -37 -25 -9 0 -27 -13 -40 -29
-22 -26 -29 -28 -76 -23 -50 5 -52 4 -46 -17 4 -16 -2 -29 -24 -49 -17 -15
-27 -32 -24 -40 6 -15 -11 -42 -26 -42 -5 0 -9 12 -9 27 l0 28 -31 -38 c-22
-27 -38 -37 -59 -37 -16 0 -32 -5 -36 -12 -5 -8 0 -9 17 -5 20 5 22 4 9 -6 -8
-7 -20 -21 -26 -32 -18 -32 -34 -30 -34 4 0 21 -5 31 -15 31 -11 0 -15 -12
-16 -42 l-2 -43 -7 35 c-7 33 -7 33 -9 7 l-1 -29 -47 35 c-46 33 -59 51 -81
107 -5 14 -17 43 -26 65 -8 22 -25 72 -37 110 -12 39 -26 75 -31 82 -5 6 -18
33 -28 60 -11 26 -19 40 -20 31 0 -14 -8 -18 -34 -18 -34 0 -35 -1 -38 -42 -3
-39 -5 -43 -27 -40 -17 1 -22 -2 -17 -13 3 -8 2 -15 -2 -15 -13 1 -42 27 -42
39 0 6 -6 11 -14 11 -18 0 -45 15 -136 74 -157 103 -363 210 -510 266 -47 17
-100 38 -118 46 -36 15 -71 18 -88 6z m2253 -164 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m13 -78 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m300 -1023 c0 -10 -4 -15 -10 -12 -5 3 -10 18
-10 33 0 21 2 24 10 12 5 -8 10 -23 10 -33z m240 -17 c0 -5 -7 -10 -16 -10 -8
0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-3280 -817 c0 -9 -29 -33 -39
-33 -14 0 -14 23 0 37 10 10 39 7 39 -4z m3705 -33 c3 -5 -1 -10 -9 -10 -9 0
-16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m308 -10 c3 -11 1 -18 -4 -14
-5 3 -9 12 -9 20 0 20 7 17 13 -6z m-4633 -60 c0 -5 -9 -10 -20 -10 -11 0 -20
5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m4506 -27 c-6 -6 -36 9 -36 18 0
5 9 4 20 -3 10 -7 18 -14 16 -15z m-771 -43 c-3 -5 -14 -10 -23 -10 -15 0 -15
2 -2 10 20 13 33 13 25 0z m-395 -301 c13 -9 13 -10 -2 -6 -9 3 -28 -3 -41
-14 -14 -10 -30 -19 -35 -19 -6 0 -14 -5 -18 -11 -4 -8 1 -9 17 -4 19 6 17 2
-10 -19 -38 -29 -68 -35 -36 -8 11 9 20 24 20 32 0 8 18 25 40 37 46 26 45 26
65 12z m-170 -37 c0 -4 -10 -13 -22 -21 -23 -14 -23 -14 -4 8 20 22 26 25 26
13z m-17 -60 c-17 -15 -44 -36 -59 -46 -16 -11 -23 -21 -16 -24 7 -2 9 -8 6
-13 -3 -6 -12 -5 -21 2 -12 11 -14 10 -11 -4 2 -11 -10 -25 -32 -39 l-35 -21
23 22 c12 12 22 27 22 34 1 20 34 54 85 86 60 38 81 40 38 3z m7 -30 c0 -5
-12 -15 -27 -23 -46 -23 -49 -22 -18 5 31 26 45 32 45 18z m15 -32 c-10 -11
-21 -17 -24 -14 -3 3 3 12 13 20 28 20 32 17 11 -6z m-45 -19 c0 -13 -188
-161 -205 -161 -3 0 1 13 11 29 9 15 53 53 98 84 84 57 96 63 96 48z m1119
-83 c5 -13 11 -34 15 -48 l7 -25 -20 24 c-12 13 -21 34 -21 47 0 30 8 31 19 2z
m-1169 3 c0 -11 -67 -71 -80 -71 -17 0 -11 10 28 45 37 35 52 42 52 26z m210
-16 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z
m-186 -120 c-14 -31 -30 -58 -35 -61 -17 -10 -9 53 8 67 9 8 23 30 31 49 11
26 17 30 20 17 2 -9 -9 -42 -24 -72z m-179 7 c-33 -32 -61 -58 -63 -57 -9 7
26 55 60 83 66 53 68 36 3 -26z m2542 36 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-874 -217 c-2 -3 -21 13 -43 34 -32 31 -42 51 -55 106 -8 37
-15 74 -15 81 0 7 27 -39 59 -102 32 -63 57 -117 54 -119z m-1543 184 c-7 -8
-17 -15 -23 -15 -5 0 -2 9 8 20 21 23 35 19 15 -5z m1320 4 c0 -6 -4 -7 -10
-4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-1416 -18 c-10 -10
-21 -17 -23 -15 -6 6 20 34 32 34 6 0 2 -8 -9 -19z m32 -53 c-18 -26 -22 -17
-5 14 6 11 13 17 16 15 3 -3 -2 -16 -11 -29z m34 -8 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-49 -53 c-11 -29 -31 -45
-31 -25 0 16 30 61 36 55 3 -2 0 -16 -5 -30z m1739 -29 c1 -21 -18 -4 -34 30
l-17 37 25 -30 c14 -16 25 -33 26 -37z m-1690 25 c0 -5 -7 -17 -15 -27 -8 -11
-13 -29 -10 -40 3 -12 2 -16 -1 -9 -3 7 -12 9 -20 6 -11 -4 -12 -1 -7 13 18
44 54 82 53 57z m-103 -60 c-9 -15 -27 -39 -41 -52 -46 -43 -27 19 23 72 30
32 39 22 18 -20z m20 -43 c-6 -16 -16 -30 -22 -30 -6 0 -16 -15 -23 -32 -7
-18 -24 -46 -38 -63 l-26 -30 7 45 c6 45 45 100 72 100 7 0 17 9 23 20 16 30
20 24 7 -10z m-113 -62 c-9 -18 -19 -28 -21 -21 -2 6 4 27 13 45 9 18 19 28
21 21 2 -6 -4 -27 -13 -45z m186 -35 c-11 -17 -11 -17 -6 0 3 10 6 24 7 30 0
9 2 9 5 0 3 -7 0 -20 -6 -30z m1930 -47 c0 -6 -7 -2 -15 8 -8 11 -15 25 -15
30 0 6 7 2 15 -8 8 -11 15 -25 15 -30z m54 13 c10 15 52 -6 71 -36 14 -21 14
-23 -4 -23 -10 0 -25 10 -34 23 -8 12 -18 19 -23 14 -4 -4 -2 -13 4 -19 16
-16 15 -39 -2 -32 -15 6 -36 47 -36 72 0 13 2 14 9 3 6 -9 11 -10 15 -2z
m-2005 -157 c1 -7 -3 -10 -9 -7 -5 3 -10 18 -9 33 0 24 1 25 9 7 5 -11 9 -26
9 -33z m2136 16 c9 -9 -45 -19 -66 -13 -30 10 -12 24 26 21 19 -1 37 -5 40 -8z
m162 -34 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2128 -49 c0
-5 -4 -3 -9 5 -5 8 -9 22 -9 30 0 16 17 -16 18 -35z m-319 12 c0 -15 -29 -57
-30 -42 0 6 3 20 6 33 7 24 24 31 24 9z m2430 -2 c0 -8 -9 -15 -20 -15 -11 0
-20 -4 -20 -10 0 -11 24 -13 58 -4 18 5 20 9 11 20 -7 9 -8 14 -1 14 6 0 17 3
26 6 12 5 10 -4 -8 -38 -12 -23 -31 -45 -40 -46 -10 -2 -33 11 -52 29 l-35 34
33 12 c44 16 48 15 48 -2z m-2056 -75 l7 -25 -20 24 c-12 13 -21 33 -21 44 0
21 0 21 14 2 7 -11 16 -31 20 -45z m46 -19 c0 -3 -9 8 -20 24 -11 17 -20 37
-20 45 1 8 10 -3 20 -24 11 -22 20 -42 20 -45z m2009 -45 c14 -14 20 -26 15
-26 -5 0 -38 30 -74 67 -61 62 -62 63 -15 26 28 -23 61 -53 74 -67z m-2332 22
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m6 -169 c-4 -4 -8 16 -7
44 1 82 8 98 11 28 2 -35 0 -68 -4 -72z m2254 99 c-3 -7 -5 -2 -5 12 0 14 2
19 5 13 2 -7 2 -19 0 -25z m-90 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m131 -88 l-1 -35 -4 34 c-3 26 -7 31 -14 20 -6 -9 -8 0 -8 31 l1
45 13 -30 c8 -16 14 -46 13 -65z m-2131 -38 c1 -51 -1 -72 -7 -62 -11 17 -14
157 -3 146 4 -3 8 -41 10 -84z m31 -82 c0 -38 -5 -70 -9 -70 -5 0 -7 32 -6 70
1 39 5 70 9 70 4 0 7 -31 6 -70z m2 -174 c0 -7 10 -21 23 -30 13 -10 17 -19
11 -22 -6 -2 -9 -16 -6 -29 2 -15 0 -25 -7 -25 -6 0 -11 11 -11 24 0 13 -7 40
-15 60 -9 21 -11 36 -5 36 5 0 10 -6 10 -14z m-153 -113 c-11 -10 -27 8 -27
30 l0 21 16 -22 c9 -13 14 -26 11 -29z m-2104 -487 c4 -9 -3 -16 -19 -19 -26
-5 -31 2 -12 21 16 16 25 15 31 -2z m634 -668 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z"/>
<path d="M3552 9829 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M3546 9744 c-9 -25 -7 -64 4 -64 6 0 10 18 10 40 0 43 -4 50 -14 24z"/>
<path d="M3531 9534 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2873 9146 c-2 -20 1 -26 6 -18 7 11 9 10 15 -3 3 -9 10 -13 16 -10
5 3 20 -1 32 -9 12 -9 28 -16 35 -16 20 0 15 21 -6 27 -11 3 -17 11 -15 18 6
16 -8 25 -50 35 -29 6 -31 5 -33 -24z"/>
<path d="M3522 9160 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3533 9075 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M3178 9033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7400 9030 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3530 8985 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M6477 8503 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M7352 8430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7500 8336 c0 -3 15 -12 33 -20 l32 -16 -24 20 c-21 19 -41 26 -41
16z"/>
<path d="M7640 8300 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M7575 8280 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M7634 8253 c3 -8 6 -17 6 -19 0 -10 18 -3 23 10 3 8 -2 16 -14 19
-15 4 -19 2 -15 -10z"/>
<path d="M7702 8198 c3 -7 13 -15 24 -17 16 -3 17 -1 5 13 -16 19 -34 21 -29
4z"/>
<path d="M7755 8153 c6 -4 26 -23 46 -41 l36 -33 1 30 c0 22 -3 28 -14 24 -8
-3 -20 -1 -27 4 -15 12 -56 27 -42 16z"/>
<path d="M6901 8104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6911 8064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7866 8071 c-4 -5 1 -16 10 -23 16 -13 133 -132 195 -199 19 -20 52
-62 74 -92 22 -31 45 -58 52 -61 18 -8 113 152 113 191 0 29 -3 33 -25 33 -14
0 -25 5 -25 11 0 31 -60 50 -104 33 -12 -5 -13 -11 -5 -27 10 -21 9 -21 -5 -3
-9 10 -13 22 -11 27 3 4 1 10 -5 14 -5 3 -10 0 -10 -7 -1 -8 -6 -6 -15 6 -11
15 -12 22 -3 27 9 6 8 11 -2 24 -8 10 -16 12 -20 6 -8 -14 -80 -2 -80 13 0 6
-8 16 -17 22 -24 14 -109 18 -117 5z m54 -11 c0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>
<path d="M8355 7943 c-9 -28 -14 -28 -31 -5 -12 15 -13 15 -14 1 0 -9 6 -22
13 -28 19 -15 51 9 44 35 -5 18 -5 17 -12 -3z"/>
<path d="M2391 7904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7025 7660 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6427 7623 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5255 7566 c-106 -39 -156 -63 -250 -124 -169 -108 -316 -254 -419
-417 -113 -176 -264 -546 -266 -649 0 -23 19 13 39 73 12 35 37 87 56 115 18
28 43 76 55 106 38 97 72 170 81 170 7 0 -41 -143 -61 -181 -13 -23 -13 -52
-1 -44 5 3 12 19 16 35 9 40 25 38 25 -2 0 -23 3 -29 9 -19 7 12 10 11 14 -5
3 -12 3 -10 2 4 -5 51 36 156 66 170 4 2 5 12 2 22 -3 11 -1 20 5 20 22 0 24
-57 3 -91 -12 -19 -21 -43 -21 -54 0 -11 13 7 29 40 89 187 98 203 126 221 13
9 41 45 62 80 20 35 41 64 45 64 4 0 8 6 8 13 0 7 14 27 30 46 52 56 23 52
-40 -6 -33 -31 -60 -58 -60 -61 0 -2 7 1 15 8 28 23 25 4 -4 -28 -16 -17 -44
-53 -63 -79 -18 -26 -36 -50 -39 -53 -3 -3 -13 -18 -22 -35 -21 -39 -23 -8 -2
41 14 33 90 114 107 114 4 0 8 7 8 16 0 8 -4 12 -10 9 -7 -4 -7 3 0 19 27 71
67 130 153 224 27 29 47 56 43 59 -10 10 76 72 166 118 80 42 176 77 238 88
16 3 11 5 -15 5 -22 1 -80 -14 -130 -32z m-475 -402 c-18 -21 -24 -10 -9 18 8
14 14 18 17 10 2 -7 -2 -19 -8 -28z m-10 -63 c0 -6 -5 -13 -11 -16 -6 -4 -8
-13 -5 -20 8 -21 -22 -41 -37 -26 -9 10 -7 16 11 28 12 9 22 22 22 29 0 8 5
14 10 14 6 0 10 -4 10 -9z m-94 -138 c-8 -15 -15 -25 -16 -21 0 12 23 58 27
54 3 -2 -2 -17 -11 -33z m-80 -50 c-8 -15 -15 -22 -16 -15 0 17 20 55 27 49 2
-3 -2 -18 -11 -34z m67 -43 c-3 -11 -9 -20 -13 -20 -5 0 -6 9 -3 20 3 11 9 20
13 20 5 0 6 -9 3 -20z m-120 -160 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0
2 -9 -1 -20z"/>
<path d="M7280 7510 c0 -16 4 -30 8 -30 5 0 7 14 4 30 -2 17 -6 30 -8 30 -2 0
-4 -13 -4 -30z"/>
<path d="M6330 7465 c-7 -9 -19 -14 -26 -11 -7 3 -16 0 -20 -5 -3 -6 -27 -20
-53 -32 -37 -17 -42 -21 -24 -24 12 -3 35 3 52 11 28 15 55 6 30 -10 -8 -4 -7
-9 2 -15 11 -8 9 -14 -11 -31 l-25 -21 28 7 c42 10 58 28 39 42 -14 10 -13 13
6 23 20 10 22 9 22 -11 0 -37 16 -54 22 -24 3 15 1 29 -4 32 -5 3 -6 14 -3 25
7 22 -13 26 -21 4 -8 -20 -34 -19 -34 0 0 9 11 20 25 25 14 5 25 14 25 20 0
15 -16 12 -30 -5z"/>
<path d="M7974 7462 c3 -5 20 -12 38 -16 46 -8 147 -77 186 -125 86 -107 91
-225 21 -435 -36 -108 -53 -139 -44 -81 4 29 3 36 -4 25 -9 -12 -11 -9 -12 15
-1 20 -4 12 -10 -23 -6 -34 -5 -60 1 -70 8 -14 9 -14 10 -2 0 12 2 12 9 2 10
-16 60 86 93 190 18 58 23 96 23 183 0 103 -2 113 -28 157 -35 59 -118 135
-172 158 -49 22 -119 35 -111 22z"/>
<path d="M6632 7390 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5860 7349 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7856 7329 c-2 -8 -9 -29 -15 -48 -17 -53 -5 -48 13 5 9 26 14 50 11
52 -2 3 -6 -1 -9 -9z"/>
<path d="M6371 7314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7561 7294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6430 7298 c0 -3 7 -21 15 -39 16 -34 16 -34 10 -4 -6 26 -25 60 -25
43z"/>
<path d="M7893 7259 c-16 -42 -17 -43 -25 -19 -4 15 -6 -6 -6 -55 1 -44 -2
-105 -5 -135 -3 -30 -4 -46 -1 -35 3 11 14 44 25 74 12 32 17 62 13 75 -7 20
-8 20 -15 -4 -6 -23 -7 -22 -8 8 -1 19 3 31 9 27 9 -6 44 94 35 103 -2 2 -12
-15 -22 -39z"/>
<path d="M6780 7276 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z"/>
<path d="M6757 7220 c3 -11 9 -22 13 -25 5 -3 6 4 3 15 -3 11 -9 22 -13 25 -5
3 -6 -4 -3 -15z"/>
<path d="M6807 7217 c3 -15 9 -30 14 -33 14 -8 10 20 -5 41 -13 18 -14 18 -9
-8z"/>
<path d="M6561 7214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6610 7157 l5 -52 3 44 c2 24 0 47 -5 52 -4 4 -6 -15 -3 -44z"/>
<path d="M7917 7204 c-4 -4 -7 -17 -7 -28 1 -19 1 -20 11 -3 11 19 8 43 -4 31z"/>
<path d="M6277 7189 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M7962 7180 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6900 7168 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M5912 7120 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5879 7108 c0 -13 -3 -17 -6 -10 -2 6 -9 12 -14 12 -13 0 1 -82 17
-101 8 -8 14 -24 14 -35 0 -11 7 -29 16 -40 14 -17 15 -17 10 -1 -3 10 -6 44
-7 75 0 31 -3 51 -6 45 -2 -7 -9 -13 -15 -13 -7 0 -8 16 -4 45 4 25 5 45 2 45
-3 0 -6 -10 -7 -22z"/>
<path d="M5451 7059 c-23 -35 -39 -65 -36 -68 6 -5 18 10 65 76 17 23 26 44
21 48 -6 3 -28 -22 -50 -56z"/>
<path d="M6891 7084 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8027 7077 c-9 -14 -14 -33 -10 -43 5 -12 9 -9 20 17 18 43 12 59
-10 26z"/>
<path d="M6990 7073 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6781 7054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8075 7049 c-10 -30 0 -33 13 -4 6 14 7 25 2 25 -5 0 -12 -9 -15 -21z"/>
<path d="M8127 7053 c4 -11 3 -14 -5 -9 -7 4 -12 1 -12 -8 0 -8 -3 -23 -7 -33
-6 -17 -6 -17 10 -2 20 21 33 69 18 69 -6 0 -8 -7 -4 -17z"/>
<path d="M5941 7027 c-1 -21 -5 -36 -10 -32 -15 8 -1 -67 15 -86 8 -8 14 -20
14 -26 0 -11 36 -24 44 -16 2 3 0 17 -5 31 -6 15 -9 40 -9 57 3 45 1 49 -16
34 -10 -8 -13 -21 -9 -37 4 -16 2 -21 -6 -17 -7 5 -9 22 -6 44 2 20 1 47 -3
61 -7 22 -8 21 -9 -13z"/>
<path d="M5369 7033 c-29 -35 -41 -53 -36 -53 2 0 17 16 31 35 28 36 31 49 5
18z"/>
<path d="M7281 7024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7882 7015 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M8156 7025 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M5850 6996 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M7272 6980 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5252 6923 c-30 -46 -24 -52 7 -7 13 19 22 36 20 39 -2 2 -15 -12
-27 -32z"/>
<path d="M7354 6934 c-7 -27 3 -142 14 -159 4 -5 14 -32 22 -60 l16 -50 -1 76
c0 62 -2 73 -12 60 -11 -14 -13 -11 -14 19 0 19 -4 46 -8 60 -4 14 -4 37 0 53
4 15 4 27 -2 27 -5 0 -12 -12 -15 -26z"/>
<path d="M7311 6934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5370 6930 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"/>
<path d="M6450 6931 c0 -6 5 -13 10 -16 6 -3 10 -16 11 -28 0 -12 3 -16 6 -9
7 18 -5 62 -17 62 -6 0 -10 -4 -10 -9z"/>
<path d="M5268 6898 c-8 -13 -20 -34 -27 -48 -12 -24 -11 -24 4 -5 19 23 48
75 41 75 -2 0 -10 -10 -18 -22z"/>
<path d="M8182 6900 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4800 6873 c-7 -21 -19 -47 -26 -58 -17 -24 -18 -51 -2 -61 8 -5 9
-2 5 9 -3 9 -2 19 3 22 11 7 44 114 37 121 -3 3 -11 -12 -17 -33z"/>
<path d="M5921 6900 c0 -8 4 -24 9 -35 5 -13 9 -14 9 -5 0 8 -4 24 -9 35 -5
13 -9 14 -9 5z"/>
<path d="M5825 6880 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6061 6870 c14 -31 19 -36 19 -24 0 6 -7 19 -16 30 -14 18 -14 18 -3
-6z"/>
<path d="M7425 6879 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M5787 6873 c-12 -21 -16 -79 -6 -93 9 -12 10 -10 6 8 -3 13 -1 37 5
53 9 28 6 51 -5 32z"/>
<path d="M5169 6774 c-43 -81 -53 -107 -54 -153 -2 -54 -2 -55 9 -21 6 19 14
40 17 45 3 6 9 23 13 39 4 15 29 50 55 77 51 52 54 58 17 33 -26 -16 -41 -7
-24 16 8 12 9 12 4 -3 -4 -11 -2 -16 5 -12 6 4 8 14 5 22 -4 9 -1 22 5 30 7 7
9 16 6 19 -3 3 -29 -39 -58 -92z"/>
<path d="M5822 6833 c-15 -28 -15 -60 0 -69 8 -5 9 -2 5 10 -4 10 -2 26 4 37
6 11 9 25 6 32 -2 7 -9 3 -15 -10z"/>
<path d="M6481 6834 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6040 6834 c0 -6 7 -19 16 -30 14 -18 14 -18 3 6 -14 31 -19 36 -19
24z"/>
<path d="M5940 6815 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M8122 6785 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M7473 6785 c4 -8 2 -17 -3 -20 -14 -9 -12 -65 1 -65 6 0 9 7 6 15 -4
8 -2 17 3 20 14 9 12 65 -1 65 -6 0 -9 -7 -6 -15z"/>
<path d="M5916 6773 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M5996 6765 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M4789 6747 c-6 -14 -7 -31 -1 -47 8 -22 10 -20 13 23 2 26 3 47 1 47
-1 0 -7 -10 -13 -23z"/>
<path d="M7675 6683 c-36 -68 -42 -85 -20 -58 18 21 58 106 53 111 -2 1 -17
-22 -33 -53z"/>
<path d="M4844 6715 c-3 -8 -4 -20 -2 -27 3 -7 7 -1 10 15 6 29 1 36 -8 12z"/>
<path d="M4655 6690 c-3 -11 -2 -28 4 -37 8 -15 10 -11 11 20 0 41 -5 47 -15
17z"/>
<path d="M4705 6700 c-3 -5 -1 -18 4 -27 9 -16 10 -14 11 10 0 28 -5 34 -15
17z"/>
<path d="M4737 6667 c-9 -15 -17 -33 -17 -41 0 -7 -7 -26 -15 -42 -9 -19 -11
-32 -5 -38 6 -6 10 -20 10 -33 0 -13 4 -23 10 -23 6 0 7 18 4 45 -5 34 -2 55
15 91 25 52 24 80 -2 41z"/>
<path d="M4819 6665 c-3 -11 0 -26 8 -33 7 -7 14 -34 14 -60 1 -26 3 -39 6
-29 2 9 8 15 13 12 4 -3 4 14 -2 37 -5 24 -13 45 -17 48 -5 3 -11 14 -13 25
-4 20 -4 20 -9 0z"/>
<path d="M6340 6672 c0 -11 27 -42 37 -42 3 0 -2 10 -13 23 -10 12 -20 24 -21
26 -2 2 -3 -1 -3 -7z"/>
<path d="M4601 6654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7842 6630 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M7702 6628 c-5 -7 -16 -26 -25 -43 -8 -16 -25 -40 -37 -52 -11 -13
-17 -23 -12 -23 13 0 45 40 70 88 22 42 23 53 4 30z"/>
<path d="M4651 6614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4790 6610 c0 -12 -7 -35 -16 -52 -9 -17 -12 -33 -7 -37 18 -12 24
-47 30 -176 3 -71 8 -132 12 -135 15 -12 42 -71 36 -80 -4 -6 6 -27 22 -47 15
-19 35 -47 44 -62 17 -27 41 -35 45 -13 1 7 0 8 -3 2 -8 -17 -26 -11 -19 6 4
9 1 23 -5 30 -20 24 -49 88 -42 93 3 3 8 0 10 -7 5 -12 53 -73 102 -127 27
-31 113 -96 119 -90 3 3 -16 26 -41 52 -26 26 -47 53 -47 59 0 18 -41 65 -51
59 -13 -9 -56 44 -70 85 -13 41 -38 72 -50 64 -5 -3 -6 2 -3 11 6 16 -13 48
-25 41 -3 -2 -4 16 -2 40 1 24 7 42 11 39 14 -9 18 20 10 80 -7 54 -8 56 -9
18 0 -24 -5 -43 -10 -43 -12 0 -25 56 -16 70 4 6 -3 20 -16 31 -12 10 -17 19
-12 19 13 0 22 79 11 86 -4 3 -8 -4 -8 -16z m55 -397 c8 -20 13 -38 10 -41 -4
-4 -35 59 -35 73 0 15 12 1 25 -32z"/>
<path d="M4467 6580 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2 0
-6 -9 -9 -20z"/>
<path d="M7832 6560 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4500 6560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6342 6540 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6462 6530 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M7690 6515 c-24 -25 -39 -45 -33 -45 10 0 83 74 83 85 0 10 -6 5 -50
-40z"/>
<path d="M6506 6530 c3 -14 10 -29 15 -34 14 -16 10 6 -7 34 l-15 25 7 -25z"/>
<path d="M4455 6531 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4601 6528 c0 -7 4 -22 9 -33 8 -18 9 -17 9 7 1 15 -3 30 -9 33 -5 3
-10 0 -9 -7z"/>
<path d="M4501 6504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4653 6471 c-9 -15 -9 -28 0 -58 l11 -38 4 33 c2 18 2 44 0 58 -3 23
-4 24 -15 5z"/>
<path d="M4742 6465 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4900 6473 c2 -23 27 -102 37 -114 17 -21 53 -120 53 -146 0 -13 5
-23 11 -23 7 0 8 16 3 48 -4 26 -8 61 -9 77 -4 73 -6 85 -14 85 -5 0 -7 -19
-5 -42 l5 -43 -31 64 c-16 35 -30 70 -30 77 0 7 -4 16 -10 19 -5 3 -10 3 -10
-2z"/>
<path d="M5060 6465 c0 -5 10 -33 22 -62 21 -50 18 -84 -3 -53 -9 12 -9 11 -4
-5 4 -11 10 -30 12 -42 3 -13 9 -23 14 -23 5 0 9 10 10 23 0 22 0 22 10 -2 5
-14 8 -30 5 -37 -3 -7 11 -43 29 -79 19 -37 35 -72 35 -80 0 -7 7 -15 15 -19
13 -5 14 -2 4 15 -6 12 -8 37 -4 59 5 31 1 46 -21 82 -17 26 -28 57 -27 78 0
19 -2 27 -4 18 -10 -40 -32 -6 -34 52 0 14 -3 19 -6 12 -6 -17 -23 -5 -23 17
0 18 -29 63 -30 46z m124 -257 c23 -45 20 -59 -4 -20 -19 31 -25 52 -16 52 3
0 12 -14 20 -32z"/>
<path d="M5122 6440 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5267 6424 c-4 -16 -9 -39 -13 -52 -5 -17 -3 -21 9 -17 18 7 30 61
18 82 -6 12 -9 9 -14 -13z"/>
<path d="M6556 6425 c3 -16 9 -55 13 -85 l8 -55 1 55 c1 30 -5 69 -13 85 l-15
30 6 -30z"/>
<path d="M7307 6439 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5171 6422 c-6 -12 -11 -29 -11 -39 1 -11 7 -4 16 16 16 37 12 55 -5
23z"/>
<path d="M4466 6421 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M4721 6414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4770 6406 c0 -8 -3 -23 -7 -33 -6 -16 -5 -16 10 -4 18 15 23 51 7
51 -5 0 -10 -6 -10 -14z"/>
<path d="M5216 6328 c-4 -42 -6 -82 -3 -90 6 -19 19 91 15 132 -3 25 -6 13
-12 -42z"/>
<path d="M4705 6360 c-4 -7 0 -19 9 -28 13 -14 16 -14 16 -2 0 25 -16 44 -25
30z"/>
<path d="M4855 6358 c-6 -15 24 -78 47 -102 8 -8 18 -33 22 -55 3 -23 10 -41
15 -41 5 0 7 -10 3 -22 -3 -14 -2 -19 6 -14 7 4 12 1 12 -8 0 -9 5 -16 10 -16
6 0 10 6 10 13 0 26 -33 112 -47 120 -7 4 -13 15 -13 23 0 9 -9 27 -20 41 -11
14 -20 34 -20 44 0 22 -18 35 -25 17z"/>
<path d="M5277 6326 c-4 -10 -7 -25 -6 -34 0 -11 4 -9 10 7 5 13 8 29 6 35 -2
5 -6 2 -10 -8z"/>
<path d="M7431 6324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5252 6310 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6731 6325 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M5041 6313 c0 -5 4 -17 9 -28 8 -18 9 -17 9 8 1 15 -3 27 -9 27 -5 0
-10 -3 -9 -7z"/>
<path d="M4730 6301 c0 -5 3 -17 6 -26 5 -12 9 -14 14 -4 5 7 6 18 3 26 -6 15
-23 18 -23 4z"/>
<path d="M4931 6294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7441 6284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6724 6242 c3 -24 7 -46 10 -49 12 -13 12 4 0 47 l-14 45 4 -43z"/>
<path d="M5070 6258 c0 -7 12 -31 25 -53 14 -22 25 -48 25 -57 0 -10 5 -18 10
-18 15 0 13 7 -20 77 -29 62 -40 76 -40 51z"/>
<path d="M5241 6254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7151 6254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7880 6229 c-30 -21 -37 -28 -20 -23 25 6 80 41 80 50 0 9 -17 1 -60
-27z"/>
<path d="M7260 6220 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3
0 -10 -9 -16 -20z"/>
<path d="M5030 6226 c0 -3 11 -31 26 -61 15 -34 23 -64 19 -76 -4 -11 -2 -25
3 -32 5 -7 12 -21 15 -32 3 -11 3 3 1 30 -4 37 -3 46 5 35 7 -9 11 -10 11 -3
0 6 -4 14 -9 17 -5 4 -15 29 -22 57 -7 28 -21 55 -31 60 -10 5 -18 7 -18 5z"/>
<path d="M5230 6195 c-2 -22 -2 -47 -1 -55 2 -8 6 -50 10 -93 4 -42 12 -82 18
-88 7 -7 8 4 2 38 -5 26 -12 91 -17 143 -6 60 -10 80 -12 55z"/>
<path d="M7945 6220 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5350 6193 c0 -22 2 -25 10 -13 13 20 13 40 0 40 -5 0 -10 -12 -10
-27z"/>
<path d="M7887 6190 c-20 -17 -20 -17 14 -13 71 8 99 14 99 21 0 15 -92 9
-113 -8z"/>
<path d="M6790 6185 c0 -11 5 -27 10 -35 8 -12 10 -12 10 5 0 11 -5 27 -10 35
-8 12 -10 12 -10 -5z"/>
<path d="M7963 6163 c-24 -8 -43 -18 -43 -23 0 -12 70 9 87 26 18 17 10 17
-44 -3z"/>
<path d="M5910 6140 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M6891 6144 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7530 6143 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M5352 6100 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7417 6113 c-29 -48 -45 -83 -39 -83 11 0 56 80 49 86 -3 3 -7 2 -10
-3z"/>
<path d="M6901 6094 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8186 6090 c14 -30 89 -129 97 -130 5 0 -6 19 -22 43 -39 53 -85 107
-75 87z"/>
<path d="M6720 6086 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5180 6073 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M5339 6033 c0 -10 -2 -27 -4 -38 -18 -96 -9 -245 15 -245 4 0 12 -15
15 -32 4 -18 15 -42 25 -53 18 -19 40 -67 40 -86 0 -5 -6 -9 -13 -9 -7 0 -19
20 -26 45 -7 24 -20 50 -29 57 -14 11 -15 10 -8 -7 5 -11 15 -39 23 -62 8 -24
21 -43 28 -43 7 0 16 -7 19 -17 12 -30 69 -113 78 -113 5 0 1 12 -10 28 -32
45 -50 90 -41 99 9 9 -17 94 -31 103 -4 3 -15 22 -24 44 -13 30 -15 63 -11
158 4 115 0 157 -8 72 -2 -26 -8 -38 -16 -36 -9 2 -11 -3 -6 -15 4 -10 9 -34
12 -53 5 -30 3 -33 -8 -22 -11 10 -13 40 -11 128 2 62 1 114 -2 114 -3 0 -6
-8 -7 -17z"/>
<path d="M5142 6020 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7126 6022 c-7 -12 28 -120 44 -135 10 -9 12 -7 7 11 -3 12 -1 22 4
22 5 0 9 -4 9 -9 0 -5 23 -23 50 -40 28 -17 50 -26 50 -20 0 6 9 8 20 4 16 -5
19 -3 13 11 -5 14 -3 15 15 5 35 -19 24 1 -24 43 -27 23 -44 33 -44 24 0 -8 3
-18 8 -23 4 -6 -7 5 -24 23 -39 40 -52 42 -25 3 12 -16 21 -37 21 -47 0 -27
-78 51 -100 99 -10 22 -20 34 -24 29z"/>
<path d="M6050 5965 c-2 -27 -3 -58 -2 -67 1 -10 -5 -18 -14 -18 -15 0 -15 -2
0 -25 9 -13 16 -31 16 -39 0 -7 7 -16 15 -20 8 -3 15 -12 15 -20 0 -8 4 -18
10 -21 6 -4 7 6 3 27 l-6 33 21 -19 c12 -11 22 -26 22 -33 0 -17 46 -69 73
-84 18 -10 18 -9 1 16 -9 14 -13 29 -9 33 4 4 10 1 12 -5 4 -12 160 -151 250
-222 33 -26 47 -32 70 -27 23 5 40 -3 100 -48 74 -56 127 -86 150 -86 23 0 14
18 -12 24 -14 4 -25 11 -25 16 0 13 31 1 57 -23 18 -16 35 -20 89 -19 37 1 64
-1 62 -4 -7 -6 -163 -30 -251 -38 -63 -6 -69 -5 -93 19 -33 33 -24 44 12 16
15 -11 33 -21 40 -21 26 0 2 27 -44 49 -26 13 -62 35 -82 50 -60 47 -67 46
-19 -5 26 -27 45 -55 42 -61 -3 -7 9 -21 26 -30 35 -21 40 -33 12 -33 -25 0
-51 26 -51 50 0 11 -18 32 -43 49 -24 16 -56 46 -71 65 -21 29 -93 76 -114 76
-15 0 10 -26 43 -44 41 -23 123 -112 112 -122 -3 -3 -18 6 -34 20 -32 30 -43
33 -43 11 0 -13 -4 -13 -27 2 -15 10 -41 33 -56 51 -16 17 -34 32 -40 32 -6 0
1 -12 14 -27 23 -25 69 -98 69 -109 0 -14 -21 1 -41 30 -13 19 -29 32 -36 29
-8 -3 -23 3 -33 12 -26 23 -36 13 -17 -17 8 -12 18 -34 22 -46 7 -23 6 -23
-19 -7 -14 9 -35 31 -46 48 l-21 32 6 -34 c6 -27 4 -32 -6 -25 -8 4 -20 16
-27 26 -7 10 -18 16 -23 13 -5 -4 -19 1 -31 9 -12 9 -27 16 -33 16 -15 0 38
-47 63 -54 11 -4 31 -24 44 -44 31 -50 30 -49 13 -49 -8 0 -15 6 -15 13 0 6
-17 27 -37 45 -33 29 -38 30 -41 14 -3 -11 8 -27 27 -42 51 -40 42 -53 -39
-53 -40 0 -70 4 -70 10 0 6 -5 10 -11 10 -15 0 -59 51 -59 68 0 8 17 1 45 -18
52 -36 55 -32 19 16 -32 41 -13 46 28 7 15 -14 31 -24 34 -20 10 10 -40 64
-55 59 -17 -6 -63 39 -57 55 6 14 -50 60 -66 55 -17 -5 -43 25 -68 78 -13 28
-27 50 -31 50 -9 0 8 -48 23 -64 4 -6 8 -17 8 -25 0 -10 -7 -8 -25 9 -30 28
-33 15 -5 -20 11 -14 20 -31 20 -38 0 -7 9 -32 20 -54 11 -23 20 -48 20 -55 0
-15 18 -49 39 -75 20 -25 3 -22 -65 9 -33 15 -70 27 -84 29 -14 1 -43 12 -64
25 -22 13 -36 17 -31 10 6 -11 3 -13 -16 -7 -13 4 -55 14 -94 22 -38 9 -81 23
-95 32 -14 9 -29 15 -33 14 -5 -1 -6 2 -3 7 3 5 -7 25 -22 45 -15 19 -35 46
-44 58 l-17 23 -1 -22 c0 -12 13 -33 31 -48 17 -14 28 -29 24 -33 -4 -4 -25
13 -46 38 -29 33 -46 67 -65 130 -54 181 -72 179 -28 -3 24 -97 31 -112 72
-158 49 -55 111 -90 194 -109 65 -16 89 -27 85 -43 -1 -6 9 -13 21 -15 18 -3
23 -9 19 -23 -5 -20 1 -23 28 -13 13 6 14 9 3 16 -8 5 6 6 36 2 27 -4 57 -15
67 -25 11 -11 30 -19 44 -19 26 0 34 15 13 23 -7 3 -1 6 15 6 28 1 32 -5 18
-27 -5 -9 -22 -13 -44 -11 -20 2 -42 -2 -49 -9 -9 -9 -4 -12 27 -13 22 0 87
-4 145 -8 67 -5 107 -5 111 2 10 15 52 1 83 -28 16 -14 31 -23 34 -20 4 3 0
10 -7 14 -7 5 -10 14 -6 20 4 8 11 9 18 3 23 -18 88 -22 104 -7 16 16 62 18
133 6 37 -7 40 -9 15 -10 -30 -2 -30 -2 -6 -14 19 -9 26 -8 36 7 8 10 19 15
25 11 6 -4 17 -1 25 5 15 12 55 5 55 -11 0 -5 9 -9 21 -9 11 0 17 5 14 10 -3
6 0 10 9 10 8 0 17 -6 20 -12 5 -10 7 -10 12 -1 3 8 15 11 30 7 17 -4 26 -1
30 10 7 19 25 22 21 4 -2 -15 3 -15 60 -4 31 6 51 3 78 -10 39 -18 55 -13 35
11 -10 12 -9 15 6 15 14 1 11 4 -10 13 -23 10 -26 15 -15 23 15 12 114 47 162
58 15 4 30 12 33 19 3 10 26 11 97 4 146 -13 203 -7 270 32 32 18 56 35 54 38
-3 2 -35 -10 -72 -28 -70 -33 -83 -35 -155 -26 -154 20 -258 46 -290 72 -8 7
-36 20 -62 30 -27 10 -48 22 -48 27 0 6 -7 8 -15 4 -8 -3 -27 6 -43 19 -16 13
-40 27 -55 30 -15 4 -41 20 -58 36 -17 16 -39 29 -49 29 -28 0 -69 39 -95 91
-14 27 -28 49 -30 49 -7 0 22 -70 35 -85 19 -23 -3 -23 -51 1 -50 26 -69 48
-69 82 0 11 -7 25 -15 32 -9 7 -31 40 -50 74 -46 81 -45 54 1 -35 37 -71 38
-104 3 -60 -10 13 -19 19 -19 15 -1 -5 -10 8 -21 29 -11 20 -24 37 -29 37 -5
0 -12 9 -15 20 -4 11 -18 34 -33 51 -23 28 -27 30 -32 13 -6 -16 -9 -14 -24
14 -19 38 -26 41 -19 10 5 -23 4 -23 -17 2 l-21 25 4 -33 c3 -26 0 -32 -12
-30 -11 2 -18 19 -22 58 l-6 55 -3 -50 -3 -50 -7 55 -7 55 -3 -50z m17 -97
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m166 -35 c3 -13 3 -23 1
-23 -6 0 -34 58 -34 72 0 17 26 -22 33 -49z m-123 22 c10 -12 10 -15 -3 -15
-8 0 -17 7 -21 15 -3 8 -2 15 3 15 5 0 14 -7 21 -15z m48 -33 c6 -18 -15 -14
-22 4 -4 10 -1 14 6 12 6 -2 14 -10 16 -16z m36 -17 c3 -8 1 -15 -4 -15 -6 0
-10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-26 -60 c-3 -3 -9 2 -12 12 -6
14 -5 15 5 6 7 -7 10 -15 7 -18z m130 -70 c15 -14 22 -25 17 -25 -12 1 -55 35
-55 44 0 11 11 6 38 -19z m150 -113 c12 -8 22 -18 22 -23 0 -9 -67 28 -76 42
-6 11 23 0 54 -19z m72 -62 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
4 10 6 0 11 -4 11 -10z m-676 -46 c14 -26 26 -53 26 -61 0 -7 9 -23 20 -35 10
-12 17 -25 14 -29 -8 -13 -41 12 -55 41 -6 14 -16 33 -21 44 -5 10 -11 26 -13
35 -2 9 -6 24 -10 34 -15 36 14 15 39 -29z m64 -6 c20 -20 14 -27 -13 -15 -14
7 -25 15 -25 20 0 11 25 8 38 -5z m752 -8 c8 -5 11 -10 5 -10 -5 0 -17 5 -25
10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m-742 -36 c27 -19 45 -51 33 -58 -11
-7 -71 47 -71 63 0 15 12 14 38 -5z m792 6 c13 -8 13 -10 -2 -10 -9 0 -20 5
-23 10 -8 13 5 13 25 0z m-333 -45 c11 -8 30 -30 42 -50 32 -56 -1 -44 -43 15
-37 51 -37 61 1 35z m-64 -42 c17 -24 27 -46 23 -50 -4 -5 -16 3 -26 17 -11
14 -29 38 -41 54 -19 24 -20 28 -5 25 10 -2 32 -23 49 -46z m207 -7 c0 -5 -9
1 -20 14 -11 13 -20 28 -20 34 0 13 39 -35 40 -48z m465 34 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-737 -78 c-10 -2 -30
8 -45 22 l-27 26 44 -22 c30 -15 39 -24 28 -26z m215 34 c20 -15 23 -38 4 -30
-14 5 -41 44 -30 44 5 0 16 -7 26 -14z m-538 -20 c18 -13 18 -14 -8 -9 -15 3
-27 9 -27 14 0 13 13 11 35 -5z m610 4 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10
10 3 6 8 10 10 10 2 0 7 -4 10 -10z m-150 -10 c3 -5 2 -10 -4 -10 -5 0 -13 5
-16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-461 -46 c3 -8 2 -12 -4 -9 -6 3
-10 10 -10 16 0 14 7 11 14 -7z m-44 -8 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10
4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M7379 5980 c-13 -10 -19 -19 -13 -20 7 0 22 9 35 20 13 10 19 19 13
20 -7 0 -22 -9 -35 -20z"/>
<path d="M5150 5970 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5718 5925 c-4 -11 -7 -40 -7 -66 1 -25 -2 -59 -5 -75 -9 -41 17 -66
34 -34 13 25 4 42 -10 20 -8 -12 -10 -10 -10 7 0 12 8 26 17 32 15 8 15 13 5
33 -6 12 -13 41 -14 63 -2 30 -5 35 -10 20z"/>
<path d="M7845 5913 c-3 -15 -8 -43 -11 -63 -6 -51 14 -17 22 38 7 49 0 67
-11 25z"/>
<path d="M5212 5900 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8300 5906 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M5141 5885 c0 -5 6 -21 14 -35 8 -14 14 -20 14 -15 0 6 -6 21 -14 35
-8 14 -14 21 -14 15z"/>
<path d="M7380 5880 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M5781 5854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5982 5829 c6 -16 12 -28 15 -26 7 7 -8 57 -18 57 -4 0 -3 -14 3 -31z"/>
<path d="M7490 5850 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M5226 5798 c-3 -13 -1 -27 4 -32 7 -7 10 1 10 22 0 37 -5 41 -14 10z"/>
<path d="M6399 5763 c16 -32 39 -67 51 -78 l23 -20 -13 25 c-21 40 -80 130
-85 130 -3 0 8 -26 24 -57z"/>
<path d="M6040 5782 c6 -13 14 -21 18 -18 3 4 -2 14 -12 24 -18 16 -18 16 -6
-6z"/>
<path d="M7803 5788 c-4 -7 -9 -22 -10 -32 -2 -11 -9 -27 -16 -35 -7 -10 -3
-8 11 3 12 11 22 27 22 36 0 9 5 21 12 28 9 9 9 12 1 12 -7 0 -15 -6 -20 -12z"/>
<path d="M8370 5799 c0 -18 166 -369 175 -369 6 0 -72 185 -116 275 -35 70
-59 108 -59 94z"/>
<path d="M7747 5742 c-15 -15 -27 -32 -27 -37 0 -5 8 0 18 11 49 55 56 75 9
26z"/>
<path d="M7604 5729 c-20 -22 -23 -28 -8 -19 18 11 50 50 41 50 -2 0 -16 -14
-33 -31z"/>
<path d="M7689 5733 l-24 -28 28 24 c15 14 27 26 27 28 0 8 -8 1 -31 -24z"/>
<path d="M5245 5711 c10 -66 25 -109 39 -114 22 -9 12 43 -19 100 -25 47 -26
47 -20 14z"/>
<path d="M5290 5700 c0 -6 6 -23 14 -38 7 -15 17 -43 21 -62 4 -19 21 -50 37
-68 16 -19 40 -52 54 -74 34 -53 41 -43 9 13 -13 24 -25 51 -25 60 0 9 -7 19
-15 22 -15 6 -40 56 -59 116 -11 33 -36 55 -36 31z"/>
<path d="M6931 5703 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M6572 5684 c2 -5 13 -26 22 -47 10 -21 20 -36 23 -33 7 7 -24 73 -39
82 -6 4 -9 3 -6 -2z"/>
<path d="M6960 5661 c-12 -24 -13 -34 -1 -27 9 6 22 46 15 46 -2 0 -8 -9 -14
-19z"/>
<path d="M6986 5634 c-17 -49 -21 -101 -10 -128 10 -26 24 -18 24 12 0 16 7
51 15 78 19 63 18 67 -5 24 -21 -38 -27 -20 -10 26 6 15 9 29 6 31 -2 2 -11
-17 -20 -43z"/>
<path d="M7449 5654 c12 -15 21 -34 21 -42 0 -8 3 -12 6 -9 10 10 -16 65 -33
71 -10 4 -8 -2 6 -20z"/>
<path d="M8180 5654 c0 -24 187 -219 265 -277 l30 -22 -22 30 c-32 44 -273
282 -273 269z"/>
<path d="M6667 5633 c8 -23 33 -60 33 -50 0 11 -29 67 -35 67 -2 0 -2 -8 2
-17z"/>
<path d="M7494 5637 c3 -10 6 -23 6 -28 0 -5 5 -9 10 -9 14 0 12 13 -6 36 -14
17 -15 17 -10 1z"/>
<path d="M7590 5612 c0 -4 7 -16 15 -26 15 -19 15 -19 15 1 0 11 -7 23 -15 27
-8 3 -15 2 -15 -2z"/>
<path d="M6740 5595 c0 -11 45 -75 53 -75 4 0 -5 18 -19 40 -25 38 -34 47 -34
35z"/>
<path d="M7592 5570 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5840 5572 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
<path d="M6164 5561 c16 -18 85 -46 93 -38 1 2 -23 16 -55 31 -50 25 -55 26
-38 7z"/>
<path d="M5940 5562 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M6385 5515 c78 -72 98 -71 22 0 -32 30 -63 55 -70 55 -7 -1 15 -25
48 -55z"/>
<path d="M6710 5561 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M7022 5510 c-1 -42 4 -74 14 -90 14 -24 15 -24 9 20 -2 25 -2 39 1
33 11 -25 22 -12 14 16 -4 16 -7 31 -7 33 1 2 -3 -3 -10 -10 -9 -11 -12 -6
-16 24 -2 25 -4 16 -5 -26z"/>
<path d="M6079 5546 c6 -8 22 -16 34 -19 22 -6 22 -6 -2 14 -27 22 -49 26 -32
5z"/>
<path d="M6830 5545 c18 -23 71 -75 77 -75 9 0 -32 46 -61 70 -22 17 -26 19
-16 5z"/>
<path d="M7072 5540 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7191 5533 c-10 -26 -10 -27 4 -9 8 11 15 23 15 28 0 15 -7 8 -19
-19z"/>
<path d="M5890 5545 c0 -11 40 -44 44 -36 3 4 -4 15 -15 24 -20 17 -29 21 -29
12z"/>
<path d="M5670 5521 c0 -5 7 -15 17 -22 15 -13 15 -12 4 9 -12 23 -21 28 -21
13z"/>
<path d="M5710 5513 c1 -10 12 -38 26 -63 14 -25 23 -37 20 -28 -3 9 -8 33
-12 52 -3 20 -10 36 -16 36 -5 0 -6 5 -3 10 3 6 1 10 -4 10 -6 0 -11 -8 -11
-17z"/>
<path d="M6046 5516 c6 -15 34 -30 34 -18 0 4 -9 13 -20 19 -16 11 -18 10 -14
-1z"/>
<path d="M7280 5500 c-6 -12 -9 -24 -7 -27 3 -2 11 5 17 17 6 12 9 24 7 27 -3
2 -11 -5 -17 -17z"/>
<path d="M5971 5507 c2 -1 13 -9 24 -17 19 -14 19 -14 6 3 -7 9 -18 17 -24 17
-6 0 -8 -1 -6 -3z"/>
<path d="M5650 5491 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M5940 5489 c0 -15 47 -62 52 -53 3 5 -7 21 -23 35 -16 15 -29 23 -29
18z"/>
<path d="M7128 5494 c-12 -11 -9 -62 3 -69 16 -10 18 -4 10 38 -3 21 -9 35
-13 31z"/>
<path d="M6530 5452 c0 -11 89 -76 125 -90 l30 -13 -33 28 c-18 16 -47 40 -65
56 -31 26 -57 35 -57 19z"/>
<path d="M7095 5451 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M6185 5440 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M5752 5391 c-10 -6 -5 -15 19 -35 17 -15 34 -24 38 -21 3 4 -1 12
-10 18 -8 7 -20 20 -25 29 -6 11 -14 14 -22 9z"/>
<path d="M7245 5390 c-11 -5 -29 -11 -39 -14 -10 -4 -16 -9 -14 -12 7 -6 78
22 78 30 0 7 1 7 -25 -4z"/>
<path d="M8524 5362 c-15 -9 -3 -40 30 -75 23 -25 55 -30 40 -6 -3 5 -10 7
-15 4 -5 -3 -14 14 -21 39 -13 48 -15 51 -34 38z"/>
<path d="M6055 5285 c17 -14 34 -25 38 -25 5 0 -5 11 -21 25 -17 14 -34 25
-38 25 -5 -1 5 -12 21 -25z"/>
<path d="M5480 5280 c0 -5 14 -10 31 -10 17 0 28 4 24 10 -3 6 -17 10 -31 10
-13 0 -24 -4 -24 -10z"/>
<path d="M5668 5243 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6925 5240 c3 -5 0 -10 -7 -10 -10 0 -10 -2 0 -9 8 -4 13 -12 13 -17
0 -5 35 -9 79 -9 44 0 80 1 80 3 0 1 -10 7 -22 12 -22 8 -22 9 5 9 15 1 27 6
27 11 0 13 -107 13 -115 0 -4 -7 -14 -5 -28 5 -25 17 -41 20 -32 5z"/>
<path d="M5565 5220 c27 -12 35 -12 35 0 0 6 -12 10 -27 9 -25 0 -26 -1 -8 -9z"/>
<path d="M7133 5223 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5660 5210 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M5755 5179 c-10 -15 -6 -16 55 -11 40 3 50 7 35 13 -29 11 -83 11
-90 -2z"/>
<path d="M6305 5180 c-4 -6 -4 -10 -1 -10 2 0 11 -3 20 -6 9 -3 16 -2 16 4 0
14 -28 23 -35 12z"/>
<path d="M6010 5170 c-25 -5 -70 -16 -100 -24 -30 -9 -66 -16 -80 -17 -14 0
-34 -5 -45 -10 -13 -6 7 -7 60 -3 44 3 88 10 98 16 10 5 45 12 78 16 33 4 60
12 60 17 0 13 -16 14 -71 5z"/>
<path d="M6235 5150 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M6168 5143 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6088 5123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3297 5106 c-3 -8 -2 -18 3 -21 13 -8 24 15 12 27 -6 6 -11 4 -15 -6z"/>
<path d="M5988 5113 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4991 4870 l-214 -5 -18 -25 c-17 -25 -28 -93 -89 -570 -11 -85 -23
-171 -25 -190 -6 -45 -42 -323 -61 -470 -25 -202 -28 -200 236 -200 176 0 198
2 224 19 29 20 30 20 57 0 26 -18 51 -19 739 -19 637 0 715 2 739 16 25 15 30
15 60 0 28 -15 76 -17 388 -17 334 0 359 2 382 19 25 18 26 18 57 0 29 -17 58
-19 393 -19 354 0 363 1 392 22 l30 21 23 -21 c21 -20 32 -21 225 -21 201 0
202 0 226 25 16 15 25 36 26 57 0 18 3 27 6 21 2 -7 13 -13 24 -13 12 0 19 -7
19 -20 0 -12 11 -32 25 -46 l25 -24 196 2 197 3 21 28 c20 28 21 39 24 490 2
296 7 472 14 490 8 22 17 27 42 27 19 0 42 -9 54 -20 l22 -21 0 -460 c0 -497
2 -522 52 -534 13 -3 111 -4 218 -1 107 2 204 4 215 4 11 -1 171 -1 355 -2
297 -1 341 1 386 17 69 24 126 75 153 137 48 112 54 408 10 526 -12 33 -25 61
-29 64 -20 15 -18 48 7 109 23 56 26 80 26 166 0 117 -15 199 -48 257 -52 92
-128 145 -240 168 -71 15 -576 16 -602 1 -11 -6 -26 -5 -42 2 -18 7 -226 11
-630 11 l-603 1 -32 -36 c-35 -41 -54 -41 -78 0 -15 28 -21 29 -90 33 -40 1
-170 1 -288 -2 l-215 -5 -17 -35 c-9 -19 -24 -91 -32 -160 -31 -254 -49 -320
-85 -320 -6 0 -11 12 -11 28 0 19 -3 23 -9 13 -10 -16 -20 25 -51 224 -33 207
-39 234 -61 246 -12 6 -119 10 -291 9 -258 0 -272 -1 -300 -21 l-29 -21 -20
21 c-19 20 -29 21 -214 21 -148 0 -197 -3 -207 -13 -23 -23 -42 -25 -67 -6
-21 17 -44 19 -220 19 -108 0 -201 -4 -207 -8 -32 -21 -34 -52 -34 -515 0
-455 0 -465 -21 -491 -32 -41 -89 -34 -114 15 -13 23 -15 104 -15 488 l0 460
-24 28 -24 28 -192 -1 c-239 -2 -228 4 -246 -151 -7 -59 -13 -113 -13 -120 -1
-7 -11 -17 -23 -24 -18 -10 -20 -9 -15 5 3 8 0 21 -7 28 -7 7 -18 42 -25 78
-24 124 -41 170 -64 174 -44 9 -136 11 -346 6z m313 -69 c6 -9 25 -132 91
-576 90 -608 105 -711 105 -727 0 -16 -15 -18 -169 -18 -94 0 -172 4 -175 8
-3 5 -8 40 -11 78 -3 38 -8 88 -11 112 l-5 42 -58 0 -58 0 -9 -117 -9 -118
-174 -3 -174 -2 6 82 c5 82 86 719 152 1206 l6 42 244 0 c134 0 246 -4 249 -9z
m594 -529 l2 -533 93 1 c50 1 100 1 110 1 16 -1 17 -12 15 -128 l-3 -128 -277
-3 -278 -2 0 665 0 665 168 -2 167 -3 3 -533z m634 291 l3 -248 53 165 c29 91
65 201 80 245 l27 80 158 3 c86 1 157 -1 157 -6 0 -7 -48 -151 -154 -460 l-46
-133 98 -314 c62 -201 99 -308 104 -297 4 9 23 148 43 307 56 456 114 885 120
895 8 12 482 14 489 2 8 -13 114 -705 162 -1052 23 -172 25 -170 55 60 91 703
130 985 137 992 5 5 116 7 247 6 l239 -3 7 -30 c4 -16 22 -124 39 -240 17
-115 46 -313 65 -440 19 -126 46 -306 60 -399 14 -93 25 -180 25 -193 l0 -23
-169 0 c-94 0 -172 4 -175 8 -2 4 -7 48 -11 97 -10 141 -7 135 -70 135 -63 0
-60 6 -70 -135 -4 -49 -9 -93 -11 -97 -7 -10 -671 -10 -678 0 -2 4 -7 48 -11
97 -10 142 -6 135 -74 135 l-58 0 -6 -112 c-4 -61 -10 -115 -15 -120 -5 -5
-156 -7 -338 -6 l-329 3 -27 95 c-15 52 -49 176 -75 275 l-48 180 -5 -275 -5
-275 -167 -3 -168 -2 0 665 0 665 170 0 170 0 2 -247z m2918 117 l0 -130 -105
0 -105 0 0 -535 0 -535 -170 0 -170 0 0 535 0 535 -100 0 -100 0 0 130 0 130
375 0 375 0 0 -130z m410 -535 l0 -665 -170 0 -170 0 0 665 0 665 170 0 170 0
0 -665z m630 655 c104 -13 158 -38 210 -97 50 -57 60 -92 67 -220 9 -161 -18
-222 -112 -253 -43 -14 -44 -25 -5 -39 50 -17 89 -50 112 -94 20 -38 23 -60
26 -214 2 -103 0 -192 -7 -223 -14 -64 -55 -123 -99 -145 -52 -24 -215 -36
-462 -33 l-225 3 -3 650 c-1 358 0 656 3 663 6 15 371 17 495 2z m-3449 -344
c13 -46 -25 -356 -43 -356 -6 0 -8 -7 -5 -14 4 -10 1 -13 -9 -9 -7 3 -14 1
-14 -5 0 -6 -9 -8 -20 -5 -10 3 -19 8 -18 12 1 3 -5 20 -12 36 -26 57 -18 141
25 252 21 56 42 100 47 97 4 -3 11 2 14 11 9 23 27 13 35 -19z m1735 8 c11 -3
28 -18 37 -34 15 -25 17 -68 17 -358 0 -333 -8 -425 -37 -436 -8 -3 -13 2 -13
15 0 11 -4 18 -8 15 -14 -9 -42 143 -102 554 -26 184 -28 207 -14 227 11 18
24 23 57 23 23 0 51 -3 63 -6z"/>
<path d="M5071 4480 c-6 -22 -59 -516 -55 -519 8 -8 94 -10 98 -2 2 3 -7 123
-19 266 -12 143 -23 258 -24 255z"/>
<path d="M7431 4482 c-7 -75 -44 -393 -52 -446 -5 -38 -7 -71 -4 -74 8 -8 94
-11 99 -4 2 4 -6 126 -19 272 -12 146 -23 259 -24 252z"/>
<path d="M8272 4482 c-1 -7 -11 -94 -22 -193 -12 -99 -25 -213 -30 -252 -5
-39 -7 -74 -4 -76 8 -8 94 -10 98 -2 2 3 -4 110 -14 236 -20 250 -26 307 -28
287z"/>
<path d="M10335 4582 c-3 -3 -5 -68 -5 -144 l0 -138 38 0 c70 0 77 13 77 140
0 101 -2 113 -20 123 -23 14 -83 26 -90 19z"/>
<path d="M10335 4082 c-3 -3 -5 -88 -5 -189 l0 -183 35 0 c25 0 44 8 61 25 25
25 25 27 22 170 -2 93 -7 148 -15 156 -13 13 -89 29 -98 21z"/>
<path d="M4180 4342 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M4148 4323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5200 2976 c0 -22 -63 -46 -127 -48 -45 -1 -49 -2 -18 -5 22 -2 46
-11 53 -21 9 -12 12 -116 12 -454 -1 -480 -2 -469 60 -522 41 -34 114 -36 154
-5 32 25 35 47 4 25 -30 -21 -78 -20 -107 3 l-24 18 2 512 c1 281 0 511 -3
511 -3 0 -6 -6 -6 -14z"/>
<path d="M2020 2971 c0 -18 -69 -41 -127 -43 -50 -1 -52 -2 -15 -5 24 -2 45
-10 48 -16 2 -7 5 -210 6 -452 3 -489 2 -476 71 -529 40 -31 104 -35 142 -10
29 19 34 39 6 24 -30 -16 -76 -12 -101 8 l-23 18 2 507 c1 279 0 507 -3 507
-3 0 -6 -4 -6 -9z"/>
<path d="M3390 2969 c0 -18 -67 -39 -132 -40 -35 -1 -49 -4 -33 -6 17 -2 40
-6 53 -8 l22 -5 1 -433 c1 -295 4 -415 10 -377 11 65 31 97 61 102 22 3 23 8
28 108 6 127 31 189 106 264 131 130 299 136 410 14 20 -22 48 -66 63 -97 24
-51 26 -67 26 -176 0 -190 -46 -296 -155 -362 -48 -29 -63 -33 -125 -33 -157
0 -260 99 -233 224 12 55 71 112 127 122 81 13 92 16 101 35 10 17 7 19 -30
19 -43 0 -120 -34 -157 -69 -111 -105 -68 -274 84 -331 233 -87 503 114 503
374 0 212 -122 359 -328 396 -101 18 -181 -5 -271 -78 -48 -39 -121 -144 -121
-174 0 -10 -5 -18 -11 -18 -7 0 -9 15 -5 43 9 64 20 517 12 517 -3 0 -6 -5 -6
-11z"/>
<path d="M1633 2845 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M1285 2867 c-178 -47 -308 -178 -351 -356 -41 -171 11 -383 120 -493
115 -114 315 -151 450 -83 30 15 65 38 77 51 38 41 96 24 101 -29 1 -12 5 66
8 173 l5 195 53 3 c28 2 52 7 52 13 0 5 -95 9 -240 9 -153 0 -240 -4 -240 -10
0 -6 35 -10 85 -10 160 0 185 -27 185 -199 0 -107 -1 -111 -27 -136 -54 -50
-120 -75 -198 -75 -105 0 -178 45 -247 152 -99 155 -117 430 -39 595 74 155
215 222 368 174 54 -17 77 -30 114 -69 50 -51 65 -89 76 -184 8 -78 20 -74 16
5 -10 181 -9 180 -61 215 -89 60 -211 83 -307 59z"/>
<path d="M8387 2840 c-131 -33 -206 -125 -195 -240 9 -96 88 -161 267 -219
124 -41 177 -77 187 -126 21 -112 -51 -172 -196 -163 -92 5 -148 41 -170 111
l-15 47 -54 0 -53 0 7 -37 c25 -134 174 -224 348 -210 261 22 339 286 122 413
-24 13 -85 38 -136 55 -104 33 -173 70 -189 99 -16 30 -12 92 10 127 51 84
239 84 299 1 9 -12 21 -38 27 -58 10 -33 14 -35 57 -38 57 -4 64 7 42 67 -22
65 -72 121 -130 147 -58 27 -170 38 -228 24z"/>
<path d="M5717 2833 c-4 -3 -7 -190 -7 -415 l0 -408 49 0 c47 0 50 2 53 28 2
15 2 201 0 415 l-2 387 -44 0 c-23 0 -46 -3 -49 -7z"/>
<path d="M6017 2833 c-4 -3 -7 -190 -7 -415 l0 -408 55 0 55 0 2 316 3 316
161 -248 c89 -137 181 -279 204 -316 l43 -68 54 0 53 0 0 416 0 415 -47 -3
-48 -3 -3 -312 c-1 -172 -6 -313 -10 -313 -4 0 -90 129 -192 286 -231 357
-221 344 -274 344 -24 0 -46 -3 -49 -7z"/>
<path d="M6760 2828 c0 -8 53 -158 118 -333 65 -176 132 -357 148 -403 l29
-83 49 3 c48 3 48 3 67 53 10 28 77 208 148 402 72 194 131 357 131 363 0 7
-19 10 -52 8 -44 -3 -54 -7 -65 -28 -7 -14 -58 -158 -114 -320 -55 -162 -105
-302 -109 -310 -7 -12 -10 -10 -18 10 -21 56 -84 236 -146 420 -37 107 -70
202 -75 210 -11 20 -111 27 -111 8z"/>
<path d="M7567 2834 c-4 -4 -7 -191 -7 -416 l0 -408 260 0 260 0 0 45 0 45
-205 0 -205 0 0 145 0 145 164 0 c90 0 171 3 180 6 11 4 16 19 16 45 l0 39
-180 0 -180 0 0 135 0 135 206 0 205 0 -3 43 -3 42 -251 3 c-138 1 -253 -1
-257 -4z"/>
<path d="M8820 2795 l0 -45 130 0 130 0 0 -371 0 -370 53 3 52 3 3 368 2 367
131 0 130 0 -3 43 -3 42 -312 3 -313 2 0 -45z"/>
<path d="M9575 2818 c-3 -13 -4 -198 -3 -413 l3 -390 54 -3 54 -3 -5 326 c-3
179 -2 322 3 318 6 -7 53 -122 211 -515 l51 -128 39 0 c36 0 40 3 55 38 9 20
67 163 130 317 62 154 117 283 121 287 4 4 6 -139 4 -317 l-4 -325 51 0 51 0
-2 413 -3 412 -63 3 -62 3 -21 -48 c-12 -26 -47 -113 -79 -193 -130 -328 -173
-430 -178 -425 -3 3 -22 47 -42 98 -138 346 -225 553 -234 559 -6 4 -37 8 -68
8 -51 0 -58 -2 -63 -22z"/>
<path d="M10580 2425 l0 -415 260 0 260 0 0 45 0 45 -210 0 -211 0 3 146 3
146 178 -2 177 -1 0 46 0 45 -180 0 -181 0 3 133 3 132 203 3 203 2 -3 43 -3
42 -252 3 -253 2 0 -415z"/>
<path d="M11240 2425 l0 -415 50 0 50 0 0 315 c0 282 5 348 23 301 3 -7 94
-149 203 -315 l197 -301 54 0 53 0 -2 413 -3 412 -50 0 -50 0 -5 -315 -5 -315
-64 100 c-36 55 -129 198 -208 318 l-143 217 -50 0 -50 0 0 -415z"/>
<path d="M11977 2833 c-4 -3 -7 -23 -7 -44 l0 -38 130 -3 131 -3 2 -365 2
-365 53 -3 52 -3 0 370 0 371 130 0 130 0 0 45 0 45 -308 0 c-170 0 -312 -3
-315 -7z"/>
<path d="M2600 2685 c-151 -38 -248 -114 -308 -242 -23 -50 -27 -70 -27 -153
1 -85 4 -102 32 -162 68 -146 211 -228 396 -228 167 0 324 94 393 235 27 55
29 69 29 170 0 100 -3 115 -28 167 -48 97 -143 176 -246 203 -82 21 -180 26
-241 10z m208 -36 c87 -42 167 -150 190 -254 20 -94 22 -122 13 -189 -12 -85
-43 -149 -101 -207 -56 -56 -118 -79 -215 -79 -111 0 -184 44 -255 152 -119
182 -80 462 77 559 84 52 204 59 291 18z"/>
<path d="M4480 2686 c-51 -11 -97 -34 -128 -63 -7 -7 -18 -13 -23 -13 -5 0 -8
-43 -8 -97 l2 -98 11 70 c13 73 27 109 56 136 99 96 312 64 374 -55 18 -37 20
-55 17 -235 -2 -183 -4 -200 -26 -249 -47 -104 -136 -157 -248 -150 -72 4
-124 35 -152 88 -58 113 7 287 114 305 109 18 181 -42 181 -151 0 -30 25 -32
40 -3 24 44 -21 127 -85 153 -82 34 -193 23 -272 -27 -87 -55 -125 -181 -81
-267 63 -121 248 -166 394 -95 59 29 117 90 136 142 7 18 17 32 23 30 5 -2 16
-48 24 -103 8 -54 15 -100 16 -102 2 -2 25 3 51 12 28 8 39 15 27 15 -12 1
-27 10 -33 20 -6 12 -10 123 -10 291 0 247 -2 274 -19 308 -23 45 -53 73 -106
100 -82 41 -192 56 -275 38z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
